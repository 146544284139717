import React, {
  useState,
  useRef,
  MutableRefObject,
  useLayoutEffect,
} from "react";
import { motion, AnimatePresence } from "framer-motion";
import { useOnClickOutside } from "hooks/useOnClickOutside";
import ChevronDown from "../icons/ChevronDown";
import useMediaQuery from "hooks/useMediaQuery";

const variants = {
  open: { height: "auto" },
  closed: { height: 0 },
};

export default function StatusDialog(props: IStatusDialog) {
  const {
    dropdownTriggerStyle,
    dropdownButtonStyle,
    activeButtonStyle,
    menuClassNames,
    element,
    menu,
    // state,
    scroll,
    noArrow,
    arrowClass,
    width,
    right,
    title,
  } = props;

  const ref = useRef<HTMLDivElement>();
  const elmRef = useRef<any>();
  const matchesMobile = useMediaQuery("(max-width: 500px)");
  // const [menuOpen, setMenuOpen] = useState(false);
  // const { open, setOpen } = {
  //   open: state ? state.menuOpen : menuOpen,
  //   setOpen: state ? state.setMenuOpen : setMenuOpen,
  // };
  const [open, setOpen] = useState(false);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [positionRight, setPositionRight] = useState<any>(0);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useLayoutEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [elmRef]);

  useLayoutEffect(() => {
    let btnpos = elmRef.current?.getBoundingClientRect();
    let right = windowWidth - btnpos.right;
    let rightProp = right;
    setPositionRight(Math.floor(rightProp));
  }, [elmRef, positionRight, windowWidth]);

  useOnClickOutside(ref, (e) => {
    e.stopPropagation();
    setOpen(false);
  });

  return (
    <div
      ref={ref as MutableRefObject<HTMLDivElement>}
      style={{
        position: "relative",
        minWidth: "fit-content",
        maxWidth: "300px",
      }}
      // role="button"
      // tabIndex={0}
      // onKeyDown={(e) => {
      //   if (e.keyCode === 13) {
      //     // e.preventDefault();
      //     // e.stopPropagation();
      //     !open && setOpen(true);
      //   } else if (e.keyCode === 27) {
      //     // e.preventDefault();
      //     // e.stopPropagation();
      //     setOpen(false);
      //   }
      // }}
      className={dropdownTriggerStyle}
      key="dropdown-container"
    >
      <button
        ref={elmRef as any}
        className={`d-flex align-items-center justify-content-between gap-1 h-100 w-100 ${
          activeButtonStyle && open
            ? "bg-secondary-subtle rounded-3 text-primary"
            : ""
        }`}
        style={{
          minWidth: "fit-content",
          width: "inherit",
          height: "inherit",
          cursor: "pointer",
          padding: dropdownButtonStyle,
        }}
        // onClick={() => preventCloseOnSelect && setOpen(!open)}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          setOpen(!open);
        }}
        // tabIndex={0}
        onKeyDown={(e) => {
          if (e.keyCode === 13) {
            // e.preventDefault();
            // e.stopPropagation();
            setOpen(open);
          } else if (e.keyCode === 27) {
            // e.preventDefault();
            // e.stopPropagation();
            setOpen(false);
          }
        }}
        key="dialog-trigger"
        title={title}
      >
        {element}
        {!noArrow && (
          <span
            style={{ transition: "transform 2s ease-in-out" }}
            className={arrowClass ? arrowClass : ""}
          >
            <ChevronDown
              style={{
                transform: open ? "scaleY(-1)" : "scaleY(1)",
                transition: "transform 0.4s ease-in-out",
              }}
            />
          </span>
        )}
      </button>
      <AnimatePresence>
        {open && (
          <motion.div
            className={`rounded-3 shadow-lg bg-white d-flex flex-column list-group align-items-center ${menuClassNames}`}
            style={
              matchesMobile
                ? {
                    position: "absolute",
                    top: `calc(100% + 1em)`,
                    zIndex: 600,
                    minWidth: "200px",
                    maxWidth: "100vw",
                    width: `calc(${windowWidth}px - 6em)`,
                    right: `calc(-${positionRight}px + 3.3em)`,
                    overflowY: scroll ? "scroll" : "hidden",
                    overflowX: "hidden",
                    maxHeight: scroll ? "300px" : "",
                  }
                : {
                    position: "absolute",
                    right: right ? right : 0,
                    top: "100%",
                    zIndex: 600,
                    overflowY: scroll ? "scroll" : "hidden",
                    overflowX: "hidden",
                    maxHeight: scroll ? "300px" : "",
                    minWidth: "200px",
                    width: width ? width : "200px",
                  }
            }
            variants={variants}
            initial="closed"
            animate="open"
            exit={variants.closed}
            transition={{ damping: 300 }}
            key={"dropdown-menu" + Math.floor(Math.random())}
            onKeyDown={(e) => {
              if (e.keyCode === 27) {
                // e.preventDefault();
                // e.stopPropagation();
                setOpen(false);
              }
            }}
          >
            {menu}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}

interface IStatusDialog {
  element: JSX.Element;
  elementClassNames?: string;
  menu: Array<JSX.Element> | JSX.Element | Function;
  menuClassNames?: string;
  state?: { menuOpen: boolean; setMenuOpen: Function };
  direction?: "up" | "down";
  scroll?: boolean;
  preventCloseOnSelect?: boolean;
  dropdownTriggerStyle?: string;
  noArrow?: boolean;
  arrowClass?: string;
  width?: string;
  right?: string;
  activeButtonStyle?: boolean;
  dropdownButtonStyle?: string;
  title?: string;
}
