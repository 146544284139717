import React, { useState } from "react";
import styles from "./Main.module.scss";
import ExpandablePaper from "components/common/ExpandablePaper";
import PageContainer from "components/common/layout/PageContainer";
import GrundIcon from "components/common/icons/Grund";
import Grund from "../Grund/Grund";
import MapMarker from "components/common/icons/MapMarker";
import SupplerendeOplysninger from "../SupplerendeOplysninger/SupplerendeOplysninger";
import { getRelevantSupplerendeOplysninger } from "util/index";
import { EjendomState, dataIsEjenom } from "../Ejendom";
import PlusIcon from "components/common/icons/PlusIcon";
import MinusIcon from "components/common/icons/MinusIcon";

export default function Main(props: MainProps) {
  const { ejendomState, listeState, opgaveState, showOpgaver } = props;
  const [minimized, setMinimized] = useState(false);

  return (
    <PageContainer classNames="w-100 p-0">
      <div className="d-flex justify-content-between pt-5 pb-4">
        <div className="d-flex flex-column gap-2 pb-2">
          <h3>BBR-oplysninger</h3>
          <p className="text-muted mb-0 fs-5 fw-medium">
            Der vises kun felter, der har indhold
          </p>
        </div>
        {ejendomState.state === "IDLE" && showOpgaver && (
          <div>
            {!minimized && (
              <button
                onClick={() => setMinimized(true)}
                className="btn btn-outline-primary d-flex align-items-center gap-2"
                style={{ width: "10em" }}
              >
                <span className="d-flex align-items-center">
                  <MinusIcon width={12} />
                </span>
                <p className="mb-0 fs-5">Minimér alle</p>
              </button>
            )}
            {minimized && (
              <button
                onClick={() => setMinimized(false)}
                className="btn btn-outline-primary d-flex align-items-center gap-2"
                style={{ width: "10em" }}
              >
                <span className="d-flex align-items-center">
                  <PlusIcon width={12} />
                </span>
                <p className="mb-0 fs-5 text-nowrap"> Udfold relevante</p>
              </button>
            )}
          </div>
        )}
      </div>
      {ejendomState.state === "LOADING" && (
        <div className={`${styles.box} rounded-2`}>
          <GrundIcon /> <span>Henter Ejendom ...</span>
        </div>
      )}
      {ejendomState.state === "IDLE" &&
        renderEjendom(
          ejendomState,
          listeState,
          opgaveState,
          minimized,
          showOpgaver
        )}
      {ejendomState.state === "ERROR" && (
        <div className={styles.box}>
          <GrundIcon /> <span>Ingen grund fundet på opslag</span>
        </div>
      )}
    </PageContainer>
  );
}

const renderEjendom = (
  ejendomState: EjendomState,
  listeState,
  opgaveState,
  minimized,
  showOpgaver
) => {
  const ejendom = dataIsEjenom(ejendomState.data)
    ? ejendomState.data
    : undefined;
  if (!ejendom) return null;

  const relevantSupplerendeOplysninger = getRelevantSupplerendeOplysninger(
    ejendom.supplerendeOplysninger,
    null
  );

  const {
    chrOplysninger,
    cvrBranchekoder,
    cvrVirksomheder,
    elOplysninger,
    energiOplysninger,
    smileyBranchekoder,
    smileyVirksomheder,
  } = relevantSupplerendeOplysninger;
  const disableExpandable =
    !chrOplysninger?.oplysninger.length &&
    !cvrBranchekoder?.oplysninger.length &&
    !cvrVirksomheder?.oplysninger.length &&
    !energiOplysninger?.oplysninger.length &&
    !elOplysninger?.oplysninger.length &&
    !smileyBranchekoder?.oplysninger.length &&
    !smileyVirksomheder?.oplysninger.length;
  return (
    <div>
      <ExpandablePaper
        headerIcon={<MapMarker />}
        title="Ejendom"
        subtitle={`BFE: ${ejendom.bfeNr}`}
        disabled={disableExpandable}
      >
        {ejendomState.data && relevantSupplerendeOplysninger && (
          <div>
            <SupplerendeOplysninger
              supplerendeOplysninger={relevantSupplerendeOplysninger}
            />
          </div>
        )}
      </ExpandablePaper>
      {renderGrunde(
        ejendomState,
        listeState,
        opgaveState,
        minimized,
        showOpgaver
      )}
    </div>
  );
};
export const formatEjerlav = (ejerlav: string) => {
  if (ejerlav) {
    const tokens = ejerlav.split(" ");
    let formattedTokens: string[] = [];
    tokens.forEach((t) => {
      if (t !== "") {
        formattedTokens.push(
          t.charAt(0).toUpperCase() + t.toLowerCase().slice(1)
        );
      }
    });
    return formattedTokens.join(" ");
  } else {
    return "";
  }
};
const renderGrunde = (
  ejendomState: EjendomState,
  listeState,
  opgaveState,
  minimized,
  showOpgaver
) => {
  const ejendom = dataIsEjenom(ejendomState.data)
    ? ejendomState.data
    : undefined;
  if (!ejendom) return null;
  let opgave: Opgave = opgaveState.data?.[opgaveState.idx];
  let liste: OpgaveListe = listeState.data;
  if ("grunde" in ejendom && ejendom.grunde.length > 0) {
    const { grunde } = ejendom;
    return grunde.map(function (grund, i) {
      let title = `Grund`;
      if (grund.jordstykker && grund.jordstykker.length > 0) {
        title = `Grund - ${grund.navnFraJordstykker}`;
      }
      return (
        <Grund
          key={grund.grund?.["id_lokalId"]}
          expand={!minimized}
          grund={grund}
          liste={liste}
          opgave={opgave}
          supplerendeOplysninger={ejendom.supplerendeOplysninger}
          title={title}
          showOpgaver={showOpgaver}
        />
      );
    });
  } else {
    return (
      <div className={styles.box}>
        <GrundIcon /> <span>Kunne ikke finde grund for BFE</span>
      </div>
    );
  }
};
interface MainProps {
  ejendomState: {
    state: "IDLE" | "LOADING" | "ERROR";
    data?: Ejendom;
  };
  listeState: {
    state: "IDLE" | "LOADING" | "ERROR";
    data?: OpgaveListe;
    opgaveOrder?: OpgaveOrder;
  };
  opgaveState: {
    state: "IDLE" | "LOADING" | "ERROR" | "EMPTY";
    idx: Number;
    data?: Opgave[];
  };
  showOpgaver: boolean;
}
