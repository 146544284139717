import React, { useEffect } from "react";
import styles from "./Info.module.scss";
import { mergeClassNames } from "util/index";
import { OpgaveListeType } from "interfaces/Opgaveliste/enums";
// import EnergiOplysninger from "../SupplerendeOplysninger/EnergiOplysninger";
import EnergiOplysningerPeriodiseret from "../SupplerendeOplysninger/EnergiOplysningerPeriodiseret";

export default function Info(props: IInfo) {
  // element is the "grund" object
  const {
    element,
    referencer,
    listeType,
    setMarkeret,
    renderEmpty,
    templater,
    // energiOplysninger,
    energiOplysningerPeriodiseretOplysning,
    energiOplysningerPeriodiseretVisning,
    visEnergiOplysningerPeriodiseretInfo,
    energiOplysningerPeriodiseretEl,
    energiOplysningerPeriodiseretElVisning,
  } = props;
  console.log(energiOplysningerPeriodiseretEl);

  let markering: boolean =
    false; /* Upper component knows what to do with this information */
  let map = templater.template;
  useEffect(() => {
    if (markering === true) {
      setMarkeret(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [referencer]);

  let render = false;
  Object.keys(map).forEach((key) => {
    if (renderEmpty || element[key] !== null) render = true;
  });
  if (!render) return null;

  return (
    <>
      <ul className={styles.container}>
        {Object.keys(map).map((key) => {
          let matched_reference = referencer?.find(
            (reference) =>
              reference.attribut_id.toLowerCase() === key.toLowerCase()
          );
          if (matched_reference && listeType !== undefined) {
            markering = true;
          }
          if (renderEmpty || element[key] != null) {
            return (
              <li
                className={mergeClassNames(
                  styles.item,
                  matched_reference
                    ? listeType === OpgaveListeType.skal
                      ? mergeClassNames(styles.markeret, styles.skal)
                      : mergeClassNames(styles.markeret, styles.kan)
                    : ""
                )}
                key={key}
              >
                <h5 className="text-body fw-semibold">
                  {map[key].displayname}
                </h5>
                <div>{templater.format(element, key, map[key].useclv)}</div>
              </li>
            );
          } else {
            return null;
          }
        })}
      </ul>

      {energiOplysningerPeriodiseretOplysning &&
        energiOplysningerPeriodiseretVisning && (
          <>
            {visEnergiOplysningerPeriodiseretInfo && (
              <ul>
                <li className={styles.info}>
                  <h5>Energidata</h5>
                  <p>
                    Energiforbrug for enheder og bygninger bliver som
                    udgangspunkt vist på bygningsniveau under
                    "Energioplysninger". Hvis det ikke er muligt, bliver
                    energiforbrug vist på grundniveau under "Energioplysninger".
                  </p>
                </li>
              </ul>
            )}

            <EnergiOplysningerPeriodiseret
              energioplysninger={energiOplysningerPeriodiseretOplysning}
              visning={energiOplysningerPeriodiseretVisning}
            />
          </>
        )}
      {energiOplysningerPeriodiseretEl &&
        energiOplysningerPeriodiseretElVisning && (
          <EnergiOplysningerPeriodiseret
            title="Forbrug af elektricitet"
            energioplysninger={energiOplysningerPeriodiseretEl}
            visning={energiOplysningerPeriodiseretElVisning}
          />
        )}
    </>
  );
}
// Interface
interface IInfo {
  element: object;
  setMarkeret: Function;
  templater: any /* TODO template type */;
  listeType?: OpgaveListeType;
  referencer?: Array<VisningsdataElementReference>;
  renderEmpty?: boolean;
  energiOplysninger?: EnergiOplysningerOplysning[];
  energiOplysningerPeriodiseretOplysning?: EnergiOplysningerPeriodiseretOplysning[];
  energiOplysningerPeriodiseretVisning?: EnergiOplysningerPeriodiseretVisning;
  energiOplysningerPeriodiseretEl?: EnergiOplysningerPeriodiseretOplysning[];
  energiOplysningerPeriodiseretElVisning?: EnergiOplysningerPeriodiseretVisning;
  visEnergiOplysningerPeriodiseretInfo?: boolean;
}
