import Modal from "components/common/Modal";
import "./MapOverride.scss";

import BbrBaseMap from "components/Map/BbrBaseMap";
import {
  constructGrunddataKeysFromEjendom,
  constructMarkersFromEjendom,
} from "components/Map/basemapUtil";
export default function FullScreenMap({ ejendom, isToggled, setIsToggled }) {
  const state = ejendom.state;

  return (
    <Modal isToggled={isToggled} setIsToggled={setIsToggled}>
      <div className="fullscreen-map-override">
        {state === "IDLE" && (
          <BbrBaseMap
            divId="ejendom-full-screen-map-container"
            containerStyle={{
              height: "80vh",
              width: "80vw",
              margin: 15,
            }}
            grunddataKeys={constructGrunddataKeysFromEjendom(ejendom.data)}
            markers={constructMarkersFromEjendom(ejendom.data)}
          />
        )}
      </div>
    </Modal>
  );
}
