import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  createContext,
  useContext,
  useLayoutEffect,
} from "react";
import FullScreenMap from "./FullScreenMap";
import Main from "./Panes/Main";
import { useURLSearchParams } from "hooks/useURLSearchParams";
import ejendomApi from "api/ejendom";
import chrApi from "api/chr";
import opgaveApi from "api/opgave";
import opgaveListeApi from "api/opgavelister";
import { motion, AnimatePresence } from "framer-motion";
// import { relative } from "path";
// import { RealTimeContext } from "../../context/RealTimeProvider";
// import { usePrevious } from "hooks/usePrevious";
import {
  // OpgaveBrugerStatus,
  // OpgaveKommentarAdgang,
  OpgaveSortOrder,
} from "interfaces/Opgaveliste/enums";
import { usePromise } from "hooks/usePromise";
import { AuthContext } from "../../context/AuthProvider";
import { Redirect, useHistory } from "react-router-dom";
import Breadcrumbs from "routes/Fejllister/Breadcrumbs";
import Ejer from "./Panes/Left/Ejer";
import Vurderingsejendom from "./Panes/Left/Vurderingsejendom";
import StickerIcon from "components/common/icons/StickerIcon";
import StatusDropdown from "./StatusDropdown";
import FolderIcon from "components/common/icons/Folder";
import ArrowRight from "components/common/icons/ArrowRight";
import ExternalLinks from "./ExternalLinks";
import ArrowUpRight from "components/common/icons/ArrowUpRight";
import { SearchResultContext } from "context/SearchResultProvider";
import StickyHeader from "components/common/StickyHeader";
import WarningBox from "components/common/WarningBox";
import useMediaQuery from "hooks/useMediaQuery";
import Expand from "components/common/icons/Expand";
import useRealTimeOpgaver from "hooks/useRealTimeOpgaver";
import BbrBaseMap from "components/Map/BbrBaseMap";
import { constructGrunddataKeysFromEjendom } from "components/Map/basemapUtil";

// Relates to "enhedModal"
// Allows the modals to see if the right pane is open or not without passing props
// If enhedModal is not used, the context is obsolete, but it's fine.
export const RightPaneOpenContext = createContext<boolean>(false);

export default function Ejendom() {
  // const { opgaveMessages } = useContext(RealTimeContext);
  // const prevOpgaveMessages = usePrevious(opgaveMessages);
  const [fullScreenMapToggled, setFullScreenMapToggled] = useState(false);
  const { redirect, resetSearch } = useContext(SearchResultContext);
  useLayoutEffect(() => {
    return () => {
      resetSearch();
    };
  }, [resetSearch]);

  const variants = {
    hidden: {
      opacity: 0,
      width: 0,
      height: 0,
      // transition: { delay: 0.08 },
    },
    show: {
      opacity: 1,
      height: "fit-content",
      transition: { delay: 0.4 },
    },
  };

  const query = useURLSearchParams();
  const queryBfe = query.get("bfe");
  const queryOpgaveid = query.get("opgaveid");
  // const querySortOrder = query.get("sortorder");
  const authState = useContext(AuthContext);
  const [showOpgaver, setShowOpgaver] = useState(queryOpgaveid ? true : false);
  const matches = useMediaQuery("(max-width: 570px)");
  // const matches1000 = useMediaQuery("(max-width: 1000px)");
  const matches1300 = useMediaQuery("(max-width: 1300px)");

  // const matches500 = useMediaQuery("(max-width: 500px)");
  const { bruger } = authState;
  const roller = bruger && bruger.roller ? bruger.roller : [];
  const history = useHistory();

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  /* DATA FETCHING */
  // Get the ejendom
  const fetchEjendom = useCallback(
    () => ejendomApi.getBfe(Number(queryBfe), true),
    [queryBfe]
  );

  // Get Chr oplysninger
  const fetchChr = useCallback(
    () => chrApi.getChr(Number(queryBfe)),
    [queryBfe]
  );

  const fetchOpgave = useCallback(
    () => opgaveApi.getBfe(Number(queryBfe), authState),
    [queryBfe, authState]
  );

  // Get kommune oplysninger
  //const fetchKommune = useCallback(() => kommuneApi.getKommune(Number(kommunenr)))
  /* Set the states */
  const ejendomState = usePromise(
    fetchEjendom,
    queryBfe // The default value ...
  ).state;

  const opgaveDefault = useMemo(() => {
    return { undefined };
  }, []);
  const { state: opgaveState, setState: setOpgaveState } = usePromise(
    fetchOpgave,
    opgaveDefault,
    { idx: 0 }
  ) as {
    state: OpgaveState;
    setState: (state: OpgaveState) => void;
  };

  useRealTimeOpgaver(opgaveState.data, (updatedOpgaver) => {
    setOpgaveState({ ...opgaveState, data: updatedOpgaver });
  });

  const setOpgaveStateHandler = (opgaveState: OpgaveState) => {
    setOpgaveState({
      ...opgaveState,
    });
  };

  // TODO: Rewrite to use "UsePromise" - this still works though.

  const [listeState, setListeState] = useState<{
    state: "IDLE" | "LOADING" | "ERROR";
    data?: OpgaveListe;
    opgaveOrder?: OpgaveOrder;
  }>({ state: "LOADING" });

  // If ejendom is done loading, and we don't already have chrOplysninger, promise chain them onto the ejendomState
  if (
    ejendomState.state === "IDLE" &&
    !ejendomState.data.supplerendeOplysninger.chrOplysninger
  ) {
    fetchChr().then((chr) => {
      ejendomState.data.supplerendeOplysninger.chrOplysninger = chr;
    });
  }

  useEffect(() => {
    let stillMounted = true;
    const fetchListeData = async (listeId, opgaveId) => {
      try {
        let sortOrder = OpgaveSortOrder.opgaveIdAsc;

        // if (querySortOrder && querySortOrder in OpgaveSortOrder) {
        //   sortOrder = parseInt(querySortOrder);
        // }
        const liste = await opgaveListeApi.getListeFromId(listeId);
        const opgaveOrder = await opgaveApi.getOpgaveOrder(
          opgaveId,
          sortOrder,
          authState
        );
        if (stillMounted && liste) {
          setListeState({
            state: "IDLE",
            data: liste,
            opgaveOrder: opgaveOrder,
          });
        }
      } catch (error) {
        if (error === "CANCEL") {
          // The request was canceled, all is well
          console.debug(error);
        } else {
          if (stillMounted) {
            // Request failed, set error state
            setListeState({ state: "ERROR" });
          }
        }
      }
    };
    if (opgaveState.state === "IDLE") {
      let idx = 0;
      if (queryOpgaveid) {
        if (opgaveState.data.length > 0) {
          idx = opgaveState.data.findIndex(
            (opgave: Opgave) => opgave.id === queryOpgaveid
          );
          if (idx === -1 && opgaveState.data[0]) {
            let listeId = opgaveState.data[0].listeId;
            let opgaveId = opgaveState.data[0].id;
            setOpgaveState({ ...opgaveState, idx: 0 });
            fetchListeData(listeId, opgaveId);
          } else {
            let listeId = opgaveState.data[idx].listeId;
            setOpgaveState({ ...opgaveState, idx: idx });
            fetchListeData(listeId, queryOpgaveid);
          }
        }
      } else {
        // if there is no query opgaveId, take the first returned.
        // ref issue #266, 248
        if (opgaveState.data.length > 0) {
          let listeId = opgaveState.data[0].listeId;
          let opgaveId = opgaveState.data[0].id;
          fetchListeData(listeId, opgaveId);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [opgaveState.idx, opgaveState.data, history, queryOpgaveid]);

  const [idx, setIdx] = useState(
    opgaveState.idx !== undefined ? opgaveState.idx : 0
  );

  const retILinks = opgaveState.data?.[opgaveState.idx]?.visningsdata?.links
    ? opgaveState.data?.[opgaveState.idx]?.visningsdata?.links?.filter(
        (o) => !o.skjul
      )
    : [];

  return (
    <>
      <div
        id="c-ejendom-container"
        className={`c-ejendom-container container-fluid d-flex justify-content-center pt-8 pb-4 bg-body-tertiary ${
          matches1300 ? "flex-column" : ""
        } `}
        style={{ minHeight: "100vh" }}
      >
        <aside className="col" aria-hidden="true"></aside>
        <div className="container-xl c-ejendom-container-content">
          {redirect.state === true && <Redirect push to={redirect.path} />}
          <Breadcrumbs
            authState={authState}
            classNames="pb-2"
            currentList={
              opgaveState.data.length > 0 && listeState.data && showOpgaver
                ? {
                    navn: listeState.data.navn,
                    id: listeState.data.id,
                  }
                : undefined
            }
            currentEjendom={{
              bfe: ejendomState.data?.bfeNr,
              state: ejendomState?.state,
            }}
          />
          {roller.findIndex((role) => role.navn === "fejllister") > -1 && (
            <StickyHeader>
              <div
                className={`container-fluid bg-white shadow-sm py-1 ${
                  showOpgaver
                    ? "border border-primary"
                    : "border border-tertiary"
                }`}
                style={{ borderRadius: "6px", minHeight: "50px" }}
              >
                <div
                  className={`row d-flex align-items-center `}
                  style={{ minHeight: "50px" }}
                >
                  <div
                    className={`c-sticky-header-heading col-lg-3 col-sm-6 h-100 d-flex px-4 py-2 align-items-center  ${
                      matches
                        ? "justify-content-center"
                        : "justify-content-between"
                    }`}
                    // style={{ minWidth: "13em" }}
                  >
                    {opgaveState.state === "LOADING" && <span>Henter...</span>}
                    {opgaveState.state === "IDLE" && (
                      <p className="mb-0 text-primary text-nowrap">
                        <span className="fw-bold">
                          {opgaveState.data?.length}
                        </span>{" "}
                        fejl på ejendommen
                      </p>
                    )}
                  </div>
                  <StatusDropdown
                    ejendomState={{ ...ejendomState, data: ejendomState.data }}
                    listeState={{
                      ...listeState,
                      data: listeState.data,
                    }}
                    opgaveState={opgaveState}
                    setOpgaveState={setOpgaveStateHandler}
                    showOpgaver={showOpgaver}
                    setShowOpgaver={setShowOpgaver}
                    brugerStatusUI={listeState.data?.brugerstatus}
                    idx={idx}
                    setIdx={setIdx}
                  />
                  {/* ejendomState={{ ...ejendomState, data: ejendomState.data }}
            opgaveState={opgaveState}
            listeState={{
              ...listeState,
              data: listeState.data,
            }} */}
                </div>
              </div>
            </StickyHeader>
          )}
          <section className="rounded-2 bg-white p-4 text-body mt-5">
            <div className="container-fluid">
              <div className="row pb-4" style={{ rowGap: "2rem" }}>
                <div className="col-12 col-lg-8 px-0">
                  <div className="mb-4 row">
                    <div className="col">
                      {ejendomState.state === "LOADING" && (
                        <>
                          <h1 className="mb-2 h2">Henter BFE...</h1>
                          <motion.div
                            className="bg-primary-subtle mb-0"
                            style={{ width: "240px", height: "1.1rem" }}
                            animate={{
                              opacity: [0.1, 0.8, 0.1],
                            }}
                            transition={{ repeat: Infinity, duration: 1.6 }}
                          ></motion.div>
                        </>
                      )}
                      {ejendomState.state === "IDLE" && (
                        <>
                          <h1 className="mb-1 h2">
                            BFE: {ejendomState.data?.bfeNr}
                          </h1>
                          <p className="mb-0 fw-semibold text-primary">
                            {ejendomState.data?.beliggenhedsadresse?.betegnelse}
                          </p>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <div
                        className="d-flex flex-column gap-4"
                        style={
                          ejendomState.state === "LOADING"
                            ? { minWidth: "50%" }
                            : {}
                        }
                      >
                        <Ejer ejendomState={ejendomState} />
                      </div>
                      <div
                        style={
                          ejendomState.state === "LOADING"
                            ? { minWidth: "50%" }
                            : {}
                        }
                      ></div>
                    </div>
                    <div className="col d-flex flex-column gap-4">
                      <div className="pe-3">
                        <h5 className="fw-semibold text-body">Kommune</h5>
                        {ejendomState.state === "LOADING" && (
                          <motion.div
                            className="bg-primary-subtle shadow-sm"
                            style={{ width: "fit-content" }}
                            animate={{
                              opacity: [0.1, 0.8, 0.1],
                            }}
                            transition={{ repeat: Infinity, duration: 1.6 }}
                          >
                            <div
                              style={{
                                height: "1rem",
                                width: "15rem",
                                maxWidth: "80%",
                              }}
                              className=""
                            ></div>
                          </motion.div>
                        )}
                        {ejendomState.state === "IDLE" && (
                          <p className="mb-0">
                            {ejendomState?.data?.kommune?.navn
                              ? `${ejendomState?.data?.kommune?.navn}${
                                  ejendomState?.data?.kommune.kommunenr === 101
                                    ? "s Kommune"
                                    : ejendomState?.data?.kommune.kommunenr ===
                                      400
                                    ? "s Regionskommune"
                                    : " Kommune"
                                }`
                              : ""}
                          </p>
                        )}
                      </div>
                      <Vurderingsejendom ejendom={ejendomState} />
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-4 px-0">
                  <div className="rounded-3 shadow-sm c-ejendom-mapcontainer">
                    {ejendomState.state === "IDLE" && (
                      <BbrBaseMap
                        divId="ejendom-thumbnail-container"
                        grunddataKeys={constructGrunddataKeysFromEjendom(
                          ejendomState.data
                        )}
                        containerStyle={{ height: 230 }}
                        markers={[]}
                        // markers={constructMarkersFromEjendom(ejendomState.data)}
                      />
                    )}
                    {ejendomState.state === "LOADING" && (
                      <motion.div
                        className="bg-primary-subtle shadow-sm"
                        animate={{
                          opacity: [0.1, 0.8, 0.1],
                        }}
                        transition={{ repeat: Infinity, duration: 1.6 }}
                      >
                        <div
                          className="d-flex align-items-center justify-content-center"
                          style={{ height: 230 }}
                        >
                          <p className="mb-0 fs-5 text-primary">
                            {" "}
                            Henter kort...
                          </p>
                        </div>
                      </motion.div>
                    )}
                    {ejendomState.state === "ERROR" && (
                      <div
                        className="d-flex align-items-center justify-content-center shadow-sm"
                        style={{ height: 230 }}
                      >
                        <p className="mb-0 text-primary">
                          Kunne ikke hente kort.
                        </p>
                      </div>
                    )}
                    {ejendomState.state === "IDLE" && (
                      <button
                        onClick={() => setFullScreenMapToggled(true)}
                        className="btn btn-outline-secondary bg-white rounded-circle m-2 d-flex justify-content-center align-items-center"
                        style={{
                          cursor: "pointer",
                          position: "absolute",
                          top: 0,
                          right: 0,
                          zIndex: 2,
                          width: "40px",
                          height: "40px",
                          border: "none",
                        }}
                      >
                        <div
                          className="text-primary"
                          style={{ height: "100%" }}
                        >
                          <Expand width={19} />
                        </div>
                      </button>
                    )}
                  </div>
                </div>
              </div>
              <AnimatePresence>
                {ejendomState.state === "IDLE" && showOpgaver && (
                  <motion.div
                    variants={variants}
                    animate={showOpgaver ? "show" : "hidden"}
                    // transition={{ type: "ease" }}
                    transition={{ damping: 300 }}
                    className="mt-3"
                  >
                    {opgaveState.data?.length ? (
                      <WarningBox>
                        <div className="col-12 col-lg-8 p-3">
                          <div className="d-flex align-items-center mb-4 gap-2 text-body">
                            <StickerIcon width={22} />
                            <h4 className="mb-0 text-body text-nowrap">
                              Fejl til gennemsyn{" "}
                              <span className="">{opgaveState?.idx + 1}</span>
                            </h4>
                          </div>
                          <div className="row">
                            {opgaveState?.data[
                              idx
                            ]?.visningsdata?.elementer?.map(
                              (element, index) => (
                                <div
                                  className={`mb-3 col-12 col-md-6`}
                                  key={index}
                                >
                                  <h5 className="fw-semibold mb-1 text-nowrap text-body">
                                    {element.info.navn}
                                  </h5>
                                  <p className="mb-0 text-body">
                                    {element.info.vaerdi
                                      ? element.info.vaerdi
                                      : "Ikke oplyst"}
                                  </p>
                                </div>
                              )
                            )}
                          </div>
                        </div>
                        <div
                          className={`col-12 col-lg-4 p-3 pt-0 ps-lg-0 pt-lg-3 d-flex flex-column`}
                        >
                          {listeState?.data?.id && (
                            <button
                              className="btn btn-link-subtle text-primary fw-semibold fs-5 p-0 mb-4 d-flex align-items-center gap-2"
                              title={`Gå til fejlliste: ${listeState.data?.navn}`}
                              onClick={() =>
                                history.push(
                                  `/fejllister/${listeState.data?.id}`
                                )
                              }
                            >
                              <FolderIcon width={20} />
                              <p className="mb-0 text-overflow-ellipsis text-start">
                                {listeState.data?.navn}
                              </p>
                              <ArrowRight width={18} />
                            </button>
                          )}
                          {retILinks && retILinks.length > 0 && (
                            <div
                              className={`d-flex flex-column border border-warning rounded-3 p-4 pt-3`}
                              style={{ minWidth: "170px" }}
                            >
                              <p className="mb-0 pb-2 fw-semibold fs-5">
                                Ret i
                              </p>
                              <div>
                                <ul className="d-flex flex-column gap-1">
                                  {opgaveState.state === "IDLE" &&
                                    renderOpgaveLinks(retILinks)}
                                </ul>
                              </div>
                            </div>
                          )}
                          {/* <div
                            className={`w-75 d-flex flex-column border border-warning rounded-3 shadow-sm p-4 w-75 `}
                            style={{ maxWidth: "300px", minWidth: "170px" }}
                          >
                            <p className="mb-0 pb-2 fw-bold fs-5">Ret i</p>
                            <a
                              className="d-flex justify-content-start gap-2 w-100 fs-5 align-items-center text-primary py-2"
                              rel="noopener noreferrer"
                              target="_blank"
                              href="https://kommune.bbr.dk/"
                            >
                              <p className="mb-0 fs-4">
                                BBR Kommune <ArrowUpRight width={18} />
                              </p>
                            </a>
                            <a
                              className="d-flex justify-content-start gap-2 w-100 fs-5 align-items-center text-primary py-2"
                              rel="noopener noreferrer"
                              target="_blank"
                              href="https://ret.bbr.dk/"
                            >
                              <p className="mb-0 fs-4">
                                Ret BBR <ArrowUpRight width={18} />
                              </p>
                            </a>
                          </div> */}
                        </div>
                      </WarningBox>
                    ) : null}
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          </section>
          <Main
            ejendomState={{ ...ejendomState, data: ejendomState.data }}
            opgaveState={opgaveState}
            listeState={{
              ...listeState,
              data: listeState.data,
            }}
            showOpgaver={showOpgaver}
          />
        </div>
        {/* c-externallinks-container  */}
        <aside className={`col ${matches1300 ? " container-xl" : ""} `}>
          <div
            className={`d-flex flex-column ${
              matches1300 ? "pt-6 pb-3" : "position-sticky pt-6 ps-6 pe-3"
            } `}
            style={{ top: "2.5rem", minWidth: 246 }}
          >
            <h5 className="mb-3 fw-bold">Eksterne links:</h5>
            <ExternalLinks ejendomState={ejendomState} />
          </div>
        </aside>
      </div>
      <FullScreenMap
        isToggled={fullScreenMapToggled}
        setIsToggled={setFullScreenMapToggled}
        ejendom={ejendomState}
      />
    </>
  );
}

const renderOpgaveLinks = (retILinks) => {
  return retILinks.map((o: VisningsdataLink, idx: number) => {
    return (
      <li key={idx}>
        <a
          title={`Åbn i ${o.system}`}
          className="external w-100"
          style={{ minWidth: 0 }}
          rel="noopener noreferrer"
          target="_blank"
          href={o.url}
        >
          <span className="text-truncate flex-1">{o.system}</span>
          <ArrowUpRight width={18} />
        </a>
      </li>
    );
  });
};

export interface EjendomState {
  state: "IDLE" | "LOADING" | "ERROR";
  data?: Ejendom;
}

export interface OpgaveState {
  state: "LOADING" | "IDLE" | "ERROR";
  data: Opgave[];
  error: any;
  idx: number;
}

export function dataIsEjenom(
  data: Ejendom | string | undefined
): data is Ejendom {
  if (!data) return false;
  if (typeof data === "string") return false;
  return "bfeNr" in data;
}

export interface ButtonState {
  state: "IDLE" | "LOADING" | "ERROR";
}
