import React from "react";
import styles from "./CvrVirksomheder.module.scss";
import ExternalArrow from "components/common/icons/ExternalArrow";

export default function CvrVirksomheder(props: ICvrVirksomhederProps) {
  const { virksomheder } = props;
  const { oplysninger } = virksomheder;
  return (
    <div className={styles.virksomhedercontainer}>
      <h4>{oplysninger.length} CVR virksomheder</h4>
      <ul>
        {oplysninger.map((o, i) => {
          const {
            navnNavn,
            beliggenhedsadresseVejnavn,
            beliggenhedsadresseHusnummerfra,
            beliggenhedsadressePostnummer,
            beliggenhedsadressePostdistrikt,
            cvrnummerrelation,
          } = o;
          return (
            <li
              className={styles.virksomhed}
              key={navnNavn + "-" + cvrnummerrelation + i}
            >
              <p>{navnNavn}</p>
              <p>
                {beliggenhedsadresseVejnavn} {beliggenhedsadresseHusnummerfra},{" "}
                {beliggenhedsadressePostnummer}{" "}
                {beliggenhedsadressePostdistrikt}
              </p>
              <p>
                CVR:{" "}
                <a
                  className="external"
                  rel="noopener noreferrer"
                  target="_blank"
                  href={`https://datacvr.virk.dk/data/visenhed?enhedstype=virksomhed&id=${cvrnummerrelation}`}
                >
                  <span>{cvrnummerrelation}</span>
                  <ExternalArrow />
                </a>
              </p>
            </li>
          );
        })}
      </ul>
    </div>
  );
}

interface ICvrVirksomhederProps {
  virksomheder: CvrVirksomheder;
}
