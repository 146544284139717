import React from "react";

export default function ErrorAnimated(props: Icon) {
  const { height, width } = props;
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 130 138"
      height={height ? height : width ? width : 16}
      width={width ? width : height ? height : 16}
      className="c-error--animated"
    >
      <circle
        className="path circle"
        fill="none"
        stroke="currentColor"
        stroke-width="10"
        stroke-miterlimit="10"
        cx="65.1"
        cy="67"
        r="62.1"
      />
      <line
        className="path line"
        fill="none"
        stroke="currentColor"
        stroke-width="10"
        stroke-linecap="round"
        stroke-miterlimit="10"
        x1="34.4"
        y1="39.6"
        x2="95.8"
        y2="92.3"
      />
      <line
        className="path line"
        fill="none"
        stroke="currentColor"
        stroke-width="10"
        stroke-linecap="round"
        stroke-miterlimit="10"
        x1="95.8"
        y1="39.6"
        x2="34.4"
        y2="92.2"
      />
    </svg>
  );
}
