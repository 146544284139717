import React, { useContext, useEffect, useState } from "react";
// import Button from "components/common/Buttons/Button";
import { motion } from "framer-motion";
import { useHistory } from "react-router-dom";
import {
  OpgaveListeType,
  OpgaveBrugerStatus,
  OpgaveKommentarAdgang,
} from "interfaces/Opgaveliste/enums";
import opgaveApi from "api/opgave";
import opgaveListeApi from "api/opgavelister";
import { AuthContext } from "context/AuthProvider";
// import DropDownMenu from "components/common/DropDownMenu";
import StatusBadge from "routes/Fejllister/StatusBadge";
import Noter from "./Noter";
import FolderIcon from "components/common/icons/Folder";
import DontShow from "components/common/icons/DontShow";
import CheckLg from "components/common/icons/CheckLg";
import StickerIcon from "components/common/icons/StickerIcon";
import useMediaQuery from "../../hooks/useMediaQuery";
import Circle from "components/common/icons/Circle";
import StatusDialog from "components/common/StatusDialog/StatusDialog";

export default function StatusDropdown(props: StatusDropdownProps) {
  const {
    // listetype,
    // ejendomState,
    // listeState,
    brugerStatusUI,
    opgaveState,
    setOpgaveState,
    showOpgaver,
    setShowOpgaver,
    idx,
    setIdx,
  } = props;

  const history = useHistory();
  const { push } = history;
  const variants = {
    hidden: { opacity: 0 },
    show: { opacity: 1 },
  };

  const matches = useMediaQuery("(max-width: 1050px)");
  const matches670 = useMediaQuery("(max-width: 670px)");
  const matches500 = useMediaQuery("(max-width: 500px)");

  const [buttonState, setButtonState] = useState<ButtonState>({
    state: "LOADING",
  });
  const authState = useContext(AuthContext);
  const currentOpgave = opgaveState.data[idx]
    ? opgaveState.data[idx]
    : undefined;

  // If the index of the opgaveState changes, push the opgave to the router
  useEffect(() => {
    if (opgaveState.idx !== undefined && idx !== undefined) {
      opgaveState.idx = idx;
      let opgave_id = opgaveState.data?.[idx]?.id;
      let liste_id = opgaveState.data?.[idx]?.listeId;
      if (opgave_id && liste_id) {
        let params = new URLSearchParams(history.location.search);
        if (params.get("opgaveid") !== opgave_id) {
          params.set("opgaveid", opgave_id);
          params.set("listeid", liste_id);
          push(history.location.pathname + "?" + params);
        }
      }
      setOpgaveState(opgaveState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idx]);

  useEffect(() => {
    if (opgaveState.idx !== undefined && idx > -1 && idx !== opgaveState.idx) {
      setIdx(opgaveState.idx);
    }
    if (opgaveState.data) {
      setButtonState({ state: "IDLE" });
    }
  }, [setIdx, opgaveState.idx, opgaveState.data, currentOpgave, idx]);

  const putOpgaveBrugerStatus = async (
    id: string,
    brugerStatus: OpgaveBrugerStatus
  ) => {
    setButtonState({ state: "LOADING" });
    try {
      let ol = await opgaveApi.putOpgaveBrugerStatus(
        id,
        brugerStatus,
        authState
      );
      if (ol && opgaveState.data) {
        let new_data = opgaveState.data;
        new_data[idx] = ol;
        setOpgaveState({ ...opgaveState, data: new_data });
      }
      return ol;
    } catch (error) {
      if (error === "CANCEL") {
        // The request was canceled, all is well
        console.debug(error);
        setButtonState({ state: "ERROR" });
      } else {
        return undefined;
      }
    }
  };

  let hasKanIkkeLoesesButton =
    brugerStatusUI !== undefined &&
    brugerStatusUI.findIndex((b) => b.status === 5 && b.visesUi) > -1;

  return (
    <>
      {opgaveState.data.length > 0 && (
        <div className="col-lg-9 col-sm-6 h-100 ">
          <div
            className={`d-flex  ${
              matches670
                ? "justify-content-evenly"
                : "justify-content-end gap-3"
            }`}
          >
            <>
              <div className="d-flex align-items-center justify-content-center gap-3 pe-0">
                <p
                  className="fs-5 mb-0 text-muted fw-medium"
                  style={{ display: matches ? "none" : "block" }}
                >
                  {showOpgaver ? "Viser:" : "Vis:"}{" "}
                </p>
                <StatusDialog
                  title="Vælg fejl til gennemsyn"
                  width="450px"
                  right={matches670 ? "-10rem" : "0"}
                  arrowClass="text-muted"
                  noArrow={matches ? true : false}
                  preventCloseOnSelect={false}
                  scroll={opgaveState.data?.length > 10 ? true : false}
                  dropdownTriggerStyle={
                    showOpgaver
                      ? ` ${
                          matches
                            ? "rounded-3 text-muted"
                            : "bg-warning-subtle rounded-2 c-statusdropdown-item gap-3 d-flex align-items-center justify-content-start"
                        }`
                      : ` ${
                          matches
                            ? "bg-body-tertiary rounded-3 "
                            : "bg-body-tertiary rounded-2 c-statusdropdown-item gap-3 d-flex align-items-center justify-content-start" // p-3 py-2
                        }`
                  }
                  activeButtonStyle={matches ? true : false}
                  dropdownButtonStyle={matches ? "" : "0.4em 1rem 0.4rem 1rem"}
                  element={
                    <>
                      {!matches && (
                        <span
                          className="d-flex align-items-center justify-content-start"
                          style={{ width: "12em" }}
                        >
                          {showOpgaver ? (
                            <h4 className="p-1 rounded-2 d-block w-100 fs-5 fw-semibold">
                              Fejl til gennemsyn {idx + 1}
                            </h4>
                          ) : (
                            <h4 className="p-1 rounded-2 d-block w-100 fs-5 fw-semibold text-muted opacity-75">
                              Vælg fejl til gennemsyn
                            </h4>
                          )}
                        </span>
                      )}
                      {matches && (
                        <span
                          className="px-2 py-2 d-flex align-items-center justify-content-center rounded hover-light hover-primary-svg"
                          style={{ width: "46px", height: "44px" }}
                        >
                          <span className="hover-svg">
                            <StickerIcon width={23} />
                          </span>
                        </span>
                      )}
                    </>
                  }
                  menu={
                    <>
                      {opgaveState?.data.map((element, index) => (
                        <button
                          className="list-group-item list-group-item-action d-flex align-items-start gap-3 py-3"
                          style={
                            matches500 ? { width: "100%" } : { width: "450px" }
                          }
                          key={index}
                          onClick={() => {
                            opgaveState.data !== undefined && setIdx(index);
                            setShowOpgaver(true);
                          }}
                        >
                          <div
                            style={{ width: "22px", height: "22px" }}
                            className="mt-1 text-primary"
                          >
                            {idx === index && <CheckLg width={22} />}
                          </div>
                          <div className="w-100 d-flex flex-column align-items-start gap-1">
                            <div className="w-100 d-flex justify-content-between  align-items-center">
                              <h3 className="h4 fw-medium text-primary mb-0">
                                Fejl til gennemsyn {index + 1}
                              </h3>
                              <div className="d-flex align-items-center gap-2">
                                <div
                                  className="c-avatar c-avatar--sm bg-primary text-white"
                                  style={{
                                    visibility: element.tilhoererBruger
                                      ?.initialer.length
                                      ? "visible"
                                      : "hidden",
                                  }}
                                >
                                  {element.tilhoererBruger?.initialer}
                                </div>
                                {element.brugerStatus === 0 && (
                                  <StatusBadge type="open">Åben</StatusBadge>
                                )}
                                {element.brugerStatus === 2 && (
                                  <StatusBadge type="done">Færdig</StatusBadge>
                                )}
                                {element.brugerStatus === 1 && (
                                  <StatusBadge type="onit">I gang</StatusBadge>
                                )}
                                {element.brugerStatus === 5 && (
                                  <StatusBadge type="pause">Fejl</StatusBadge>
                                )}
                              </div>
                            </div>
                            <div className="text-muted d-flex align-items-center gap-2">
                              <FolderIcon width={20} height={20} />
                              <ListName id={element.listeId} />
                            </div>
                          </div>
                        </button>
                      ))}
                      {showOpgaver && (
                        <button
                          className="list-group-item list-group-item-action d-flex align-items-start gap-3 py-3"
                          style={{ width: "450px" }}
                          onClick={() => {
                            setShowOpgaver(false);
                            setIdx(-1);
                          }}
                        >
                          <div className="mb-0 text-muted d-flex align-items-center gap-3">
                            <DontShow
                              width={18}
                              style={{ minWidth: "23px", height: "22px" }}
                            />
                            <p className="mb-0">Vis ikke fejl</p>
                          </div>
                        </button>
                      )}
                    </>
                  }
                />

                <span
                  style={{
                    height: "3rem",
                    visibility: showOpgaver ? "visible" : "hidden",
                  }}
                  className={`c-divider border-start px-2 border-1 divider--show`}
                ></span>
              </div>
              <>
                <motion.div
                  variants={variants}
                  animate={showOpgaver ? "show" : "hidden"}
                  transition={{ damping: 300, delay: 0.2 }}
                  className="d-flex align-items-center"
                >
                  <StatusDialog
                    width={matches ? "260px" : "210px"}
                    arrowClass="text-muted"
                    dropdownTriggerStyle={` ${
                      matches
                        ? "rounded-3 text-muted"
                        : "h-100 c-statusdropdown-item gap-3 d-flex align-items-center justify-content-start"
                    }`}
                    activeButtonStyle={matches ? true : false}
                    noArrow={matches ? true : false}
                    element={
                      <>
                        {!matches && (
                          <div className="d-flex gap-2 align-items-center h-100 pe-2">
                            <p className="mb-0 p-1 text-muted fw-medium fs-5">
                              Status:
                            </p>
                            <div className="d-flex gap-2">
                              <motion.div
                                className="c-avatar c-avatar--sm bg-primary text-white"
                                variants={variants}
                                animate={
                                  currentOpgave?.tilhoererBruger?.initialer
                                    .length
                                    ? "show"
                                    : "hidden"
                                }
                                transition={{ damping: 300 }}
                              >
                                {currentOpgave?.tilhoererBruger?.initialer}
                              </motion.div>
                              {currentOpgave?.brugerStatus === 0 && (
                                <StatusBadge type="open">Åben</StatusBadge>
                              )}
                              {currentOpgave?.brugerStatus === 2 && (
                                <StatusBadge type="done">Færdig</StatusBadge>
                              )}
                              {currentOpgave?.brugerStatus === 1 && (
                                <StatusBadge type="onit">I gang</StatusBadge>
                              )}
                              {currentOpgave?.brugerStatus === 5 && (
                                <StatusBadge type="pause">Fejl</StatusBadge>
                              )}
                            </div>
                          </div>
                        )}
                        {matches && (
                          <span
                            className="px-2 py-2 d-flex align-items-center justify-content-center rounded hover-light hover-primary-svg"
                            style={{ width: "46px", height: "44px" }}
                          >
                            <span className="hover-svg">
                              <Circle width={23} />
                            </span>
                          </span>
                        )}
                      </>
                    }
                    menu={[
                      <button
                        className="list-group-item list-group-item-action d-flex w-100 justify-content-start gap-3 ps-4 pe-5 py-2"
                        // key={currentOpgave.id + 0}
                        // key={0}
                        disabled={buttonState.state !== "IDLE"}
                        onClick={() => {
                          if (currentOpgave)
                            putOpgaveBrugerStatus(
                              currentOpgave.id,
                              currentOpgave.brugerStatus ===
                                OpgaveBrugerStatus.iGang
                                ? OpgaveBrugerStatus.ikkeTildelt
                                : OpgaveBrugerStatus.iGang
                            );
                        }}
                      >
                        <div style={{ minWidth: "20px" }}>
                          {currentOpgave?.brugerStatus ===
                            OpgaveBrugerStatus.iGang && <CheckLg width={20} />}
                        </div>
                        <div style={{ minWidth: "30px" }}>
                          <div
                            className="c-avatar c-avatar--sm bg-primary text-white"
                            style={{
                              visibility:
                                currentOpgave?.tilhoererBruger?.initialer
                                  .length &&
                                currentOpgave.brugerStatus ===
                                  OpgaveBrugerStatus.iGang
                                  ? "visible"
                                  : "hidden",
                            }}
                          >
                            {currentOpgave?.tilhoererBruger?.initialer}
                          </div>
                        </div>
                        <StatusBadge type="onit">I gang</StatusBadge>
                      </button>,
                      <button
                        // key={1}
                        disabled={buttonState.state !== "IDLE"}
                        className="list-group-item list-group-item-action d-flex w-100 justify-content-start gap-3 ps-4 pe-5 py-2"
                        onClick={() => {
                          if (currentOpgave)
                            putOpgaveBrugerStatus(
                              currentOpgave.id,
                              (currentOpgave.brugerStatus =
                                OpgaveBrugerStatus.opgaveLoest)
                            );
                        }}
                        // onKeyDown={(e) => {
                        //   if (e.keyCode === 13) {
                        //     e.preventDefault();
                        //     e.stopPropagation();
                        //   }
                        // }}
                      >
                        <div style={{ minWidth: "20px" }}>
                          {currentOpgave?.brugerStatus ===
                            OpgaveBrugerStatus.opgaveLoest && (
                            <CheckLg width={20} />
                          )}
                        </div>
                        <div style={{ minWidth: "30px" }}>
                          <div
                            className="c-avatar c-avatar--sm bg-primary text-white"
                            style={{
                              visibility:
                                currentOpgave?.tilhoererBruger?.initialer
                                  .length &&
                                currentOpgave.brugerStatus ===
                                  OpgaveBrugerStatus.opgaveLoest
                                  ? "visible"
                                  : "hidden",
                            }}
                          >
                            {currentOpgave?.tilhoererBruger?.initialer}
                          </div>
                        </div>
                        <StatusBadge type="done">Færdig</StatusBadge>
                      </button>,
                      <button
                        // key={2}
                        className="list-group-item list-group-item-action d-flex w-100 justify-content-start gap-3 ps-4 pe-5 py-2"
                        disabled={buttonState.state !== "IDLE"}
                        onClick={() => {
                          if (currentOpgave)
                            putOpgaveBrugerStatus(
                              currentOpgave.id,
                              (currentOpgave.brugerStatus =
                                OpgaveBrugerStatus.ikkeTildelt)
                            );
                        }}
                        // onKeyDown={(e) => {
                        //   if (e.keyCode === 13) {
                        //     e.preventDefault();
                        //     e.stopPropagation();
                        //   }
                        // }}
                      >
                        <div style={{ minWidth: "20px" }}>
                          {currentOpgave?.brugerStatus ===
                            OpgaveBrugerStatus.ikkeTildelt && (
                            <CheckLg width={20} />
                          )}
                        </div>
                        <div style={{ minWidth: "30px" }}>
                          <div
                            className="c-avatar c-avatar--sm bg-primary text-white"
                            style={{
                              visibility:
                                currentOpgave?.tilhoererBruger?.initialer
                                  .length &&
                                currentOpgave.brugerStatus ===
                                  OpgaveBrugerStatus.ikkeTildelt
                                  ? "hidden"
                                  : "hidden",
                            }}
                          >
                            {currentOpgave?.tilhoererBruger?.initialer}
                          </div>
                        </div>
                        <StatusBadge type="open">Åben</StatusBadge>
                      </button>,
                      <button
                        style={
                          hasKanIkkeLoesesButton
                            ? { display: "flex" }
                            : { display: "none" }
                        }
                        // key={3}
                        className="list-group-item list-group-item-action w-100 justify-content-start gap-3 ps-4 pe-5 py-2"
                        onClick={() => {
                          if (currentOpgave)
                            putOpgaveBrugerStatus(
                              currentOpgave.id,
                              (currentOpgave.brugerStatus =
                                OpgaveBrugerStatus.kanIkkeLoeses)
                            );
                        }}
                        // onKeyDown={(e) => {
                        //   if (e.keyCode === 13) {
                        //     e.preventDefault();
                        //     e.stopPropagation();
                        //   }
                        // }}
                      >
                        <div style={{ minWidth: "20px" }}>
                          {currentOpgave?.brugerStatus ===
                            OpgaveBrugerStatus.kanIkkeLoeses && (
                            <CheckLg width={20} />
                          )}
                        </div>
                        <div style={{ minWidth: "30px" }}>
                          <div
                            className="c-avatar c-avatar--sm bg-primary text-white"
                            style={{
                              visibility:
                                currentOpgave?.tilhoererBruger?.initialer
                                  .length &&
                                currentOpgave.brugerStatus ===
                                  OpgaveBrugerStatus.kanIkkeLoeses
                                  ? "visible"
                                  : "hidden",
                            }}
                          >
                            {currentOpgave?.tilhoererBruger?.initialer}
                          </div>
                        </div>
                        <StatusBadge type="pause">Fejl</StatusBadge>
                      </button>,
                    ]}
                  />
                </motion.div>

                <div className="d-flex align-items-center">
                  {currentOpgave?.kommentarAdgang !==
                  OpgaveKommentarAdgang.ingenAdgang ? (
                    <>
                      <span
                        style={{
                          height: "3rem",
                          visibility: showOpgaver ? "visible" : "hidden",
                        }}
                        className={`c-divider border-start px-2 border-1 divider--show`}
                      ></span>
                      <motion.div
                        variants={variants}
                        animate={showOpgaver ? "show" : "hidden"}
                        transition={{ damping: 300, delay: 0.2 }}
                      >
                        <div className="d-flex align-items-center text-muted">
                          <Noter
                            opgaveState={opgaveState}
                            setOpgaveState={setOpgaveState}
                            title="Noter"
                            icon
                          />
                        </div>
                      </motion.div>
                    </>
                  ) : (
                    <>
                      <motion.div
                        variants={variants}
                        animate={showOpgaver ? "show" : "hidden"}
                        transition={{ damping: 300, delay: 0.2 }}
                        style={{ minWidth: "120px" }}
                      ></motion.div>
                    </>
                  )}
                </div>
              </>
            </>
          </div>
        </div>
      )}
    </>
  );
}

export function ListName(props: { id: string }) {
  const [name, setName] = useState<string>();
  const getListe = async (id: string) => {
    const result = await opgaveListeApi.getListeFromId(id);
    setName(result.navn);
  };
  getListe(props.id);
  return (
    <p
      className="small mb-0 text-start text-truncate"
      style={{
        width: "280px",
      }}
    >
      {name ? name : "Henter..."}
    </p>
  );
}

interface StatusDropdownProps {
  ejendomState: {
    state: "IDLE" | "LOADING" | "ERROR";
    data?: Ejendom;
  };
  opgaveState: {
    state: "LOADING" | "IDLE" | "ERROR";
    data: Opgave[];
    error: any;
    idx: number;
  };
  listeState: {
    state: "IDLE" | "LOADING" | "ERROR";
    data?: OpgaveListe;
    opgaveOrder?: OpgaveOrder;
  };
  setOpgaveState: Function;
  listetype?: OpgaveListeType;
  brugerStatusUI?: OpgaveBrugerStatusUI[];
  showOpgaver: boolean;
  setShowOpgaver: Function;
  idx: number;
  setIdx: Function;
}

export interface ButtonState {
  state: "IDLE" | "LOADING" | "ERROR";
}
