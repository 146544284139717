import React, { useState, useEffect } from "react";
import styles from "./BygningInfo.module.scss";
import WarningLabel from "components/common/PriorityLabels/WarningLabel";
import FoldOutSection from "components/common/FoldOutSection";
import Info from "../Info";
import Enheder from "./Information/Enheder";
import {
  ArealTemplate,
  EnergioplysningerTemplate,
  VandogafloebTemplate,
  EnhederTemplate,
  OverblikTemplate,
  MaterialerTemplate,
} from "../../Template";
import { mergeClassNames, getRelevantSupplerendeOplysninger } from "util/index";
import SupplerendeOplysninger from "routes/Ejendom/SupplerendeOplysninger/SupplerendeOplysninger";
import BygningPaaFremmedGrund from "./BygningPaaFremmedGrund";
import Arealer from "./Arealer";

export default function BygningInfo(props: IBygningInfoProps) {
  const {
    bygning,
    setBygningMarkeret,
    liste,
    opgave,
    expand,
    supplerendeOplysninger,
    showOpgaver,
  } = props;
  const [energiMarkeret, setEnergiMarkeret] = useState(false);
  const [vandMarkeret, setVandMarkeret] = useState(false);
  const [arealMarkeret, setArealMarkeret] = useState(false);
  const [enhederMarkeret, setEnhederMarkeret] = useState(false);
  const [materialerMarkeret, setMaterialerMarkeret] = useState(false);
  //eslint-disable-next-line
  const [overblikMarkeret, setOverblikMarkeret] = useState(false);

  const relevantSupplerendeOplysninger = supplerendeOplysninger
    ? getRelevantSupplerendeOplysninger(
        supplerendeOplysninger,
        bygning.bygning.id_lokalId
      )
    : undefined;

  const bbrid = bygning.bygning.id_lokalId;
  const energiOplysningerBygning =
    supplerendeOplysninger?.energiOplysninger?.oplysninger?.filter(
      (oplysning) => oplysning.bbrId === bbrid
    );
  const energiOplysningerPeriodiseretBygningOplysninger =
    supplerendeOplysninger?.energiOplysningerPeriodiseret?.oplysninger?.filter(
      (oplysning) => oplysning.bbrId === bbrid
    );
  const energiOplysningerPeriodiseretBygningVisning =
    supplerendeOplysninger?.energiOplysningerPeriodiseret?.visninger?.find(
      (oplysning) => oplysning.bbrId === bbrid
    );
  const energiOplysningerPeriodiseretElBygning =
    supplerendeOplysninger?.energiOplysningerPeriodiseretEl?.oplysninger?.filter(
      (oplysning) => oplysning.bbrId === bbrid
    );
  const energiOplysningerPeriodiseretElBygningVisning =
    supplerendeOplysninger?.energiOplysningerPeriodiseretEl?.visninger?.find(
      (oplysning) => oplysning.bbrId === bbrid
    );

  const getOpgaveReference = (bygning, opgave: Opgave) => {
    // There can be multiple acceptable id's not only the building
    // Bygning
    // Enheder

    // This needs to be a list of all "id_lokalId"
    let ids: Array<string> = [];
    ids.push(bygning.bygning.id_lokalId);
    if (bygning.opgangList) {
      bygning.opgangList.forEach((o, idx) => {
        if (o.enhedList) {
          o.enhedList.forEach((o, idx) => {
            ids.push(o?.enhed?.id_lokalId);
          });
        }
      });
    }

    let visningsdata: Visningsdata = opgave?.visningsdata;
    let referencer: Array<VisningsdataElementReference> = [];

    if (visningsdata) {
      visningsdata?.elementer?.forEach((o: VisningsdataElement, idx) => {
        o?.referencer?.forEach((r: VisningsdataElementReference, idx) => {
          if (ids.includes(r.bbr_id)) {
            // Sub-units on the building will be added if push_flag is set
            if (r.attribut_kilde === undefined) {
              r.attribut_kilde = "bbr";
            }
            referencer.push(r);
          }
        });
      });
    }
    return referencer;
  };
  const referencer: Array<VisningsdataElementReference> =
    opgave !== undefined ? getOpgaveReference(bygning, opgave) : [];

  // If the reference list for the opgave has atleast 1 element, mark the building
  useEffect(() => {
    if (referencer.length > 0) {
      setBygningMarkeret(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [referencer]);

  const sections = [
    {
      title: "Energioplysninger",
      markeret: energiMarkeret,
      component: (
        <Info
          element={bygning.bygning}
          referencer={referencer}
          renderEmpty={true}
          templater={new EnergioplysningerTemplate()}
          setMarkeret={setEnergiMarkeret}
          listeType={liste?.listetype}
          energiOplysninger={energiOplysningerBygning}
          energiOplysningerPeriodiseretOplysning={
            energiOplysningerPeriodiseretBygningOplysninger
          }
          energiOplysningerPeriodiseretVisning={
            energiOplysningerPeriodiseretBygningVisning
          }
          energiOplysningerPeriodiseretEl={
            energiOplysningerPeriodiseretElBygning
          }
          energiOplysningerPeriodiseretElVisning={
            energiOplysningerPeriodiseretElBygningVisning
          }
        />
      ),
    },
    {
      title: "Arealer",
      markeret: arealMarkeret,
      component: (
        <>
          <Info
            element={bygning.bygning}
            referencer={referencer}
            templater={new ArealTemplate()}
            setMarkeret={setArealMarkeret}
            listeType={liste?.listetype}
          />
          <Arealer bygning={bygning.bygning} arealer={bygning.arealer} />
        </>
      ),
    },
    {
      title: "Vand- og afløbsforhold",
      markeret: vandMarkeret,
      component: (
        <Info
          element={bygning.bygning}
          referencer={referencer}
          renderEmpty={true}
          templater={new VandogafloebTemplate()}
          setMarkeret={setVandMarkeret}
          listeType={liste?.listetype}
        />
      ),
    },
    {
      title: "Enheder",
      markeret: enhederMarkeret,
      component: (
        <Enheder
          opgangList={bygning.opgangList}
          referencer={referencer}
          templater={new EnhederTemplate()}
          setMarkeret={setEnhederMarkeret}
          listeType={liste?.listetype}
          supplerendeOplysninger={supplerendeOplysninger}
        />
      ),
    },
    {
      title: "Materialer",
      markeret: materialerMarkeret,
      component: (
        <Info
          element={bygning.bygning}
          referencer={referencer}
          renderEmpty={true}
          templater={new MaterialerTemplate()}
          setMarkeret={setMaterialerMarkeret}
          listeType={liste?.listetype}
        />
      ),
    },
  ];
  return (
    <div className={styles.container}>
      <Info
        element={bygning.bygning}
        referencer={referencer}
        templater={new OverblikTemplate()}
        setMarkeret={setOverblikMarkeret}
        listeType={liste?.listetype}
        renderEmpty={false}
      />

      {bygning.bygning.bygningPåFremmedGrundList &&
        bygning.bygning.bygningPåFremmedGrundList.map(
          (bygningPåFremmedGrund) => {
            // TODO: Is it possible to have more than one BygningPaaFremmedGrund, and in what circumstances?
            return (
              <BygningPaaFremmedGrund
                key={bygningPåFremmedGrund.id}
                BygningPaaFremmedGrund={
                  bygningPåFremmedGrund.bygningPaaFremmedGrund
                }
              ></BygningPaaFremmedGrund>
            );
          }
        )}

      {sections.map((section, idx) => {
        return (
          <FoldOutSection
            key={section.title}
            element={
              <h4
                className={mergeClassNames(
                  styles.header,
                  section.markeret ? styles.prioritet : ""
                )}
              >
                {section.title}
              </h4>
            }
            elementClassNames={styles.header}
            sectionClassNames={styles.row}
            opgaveIcon={
              section.markeret && showOpgaver ? <WarningLabel /> : undefined
            }
            isOpen={expand && section.markeret ? true : false}
          >
            {section.component}
          </FoldOutSection>
        );
      })}
      {relevantSupplerendeOplysninger && (
        <SupplerendeOplysninger
          supplerendeOplysninger={relevantSupplerendeOplysninger}
        />
      )}
    </div>
  );
}

interface IBygningInfoProps {
  bygning: Bygning;
  setBygningMarkeret: Function;
  expand: boolean;
  liste?: OpgaveListe;
  opgave?: Opgave;
  adresse?: string;
  postnr?: number;
  supplerendeOplysninger?: SupplerendeOplysninger;
  showOpgaver: boolean;
}
