import React, { useState, useEffect, Fragment, useContext } from "react";
import { useHistory } from "react-router-dom";
import styles from "./OpretListe.module.scss";
import {
  OpgaveListeType,
  OpgaveListeStatus,
  ListeKommentarType,
} from "interfaces/Opgaveliste/enums";
import opgavelisteApi from "api/opgavelister";
import artikelApi from "api/artikler";
import organisationApi from "api/organisation";
import ListeAdgange from "./ListeAdgange";
import { ToastContext } from "context/ToastProvider";
import { Spacing } from "components/common/layout/Spacing";
import ChevronRight from "components/common/icons/ChevronRight";

export interface IPostOpgaveListeState {
  state: "IDLE" | "LOADING" | "ERROR" | "OK";
  code?: number; // HTTP status code
  data?: any; // HTTP response
}

export interface IArtikelListState {
  state: "IDLE" | "LOADING" | "ERROR" | "OK";
  data?: Artikel[];
}

export interface IOrganisationState {
  state: "IDLE" | "LOADING" | "ERROR";
  data: Organisation[];
}

export default function OpretListe(props: IOpretListe) {
  const history = useHistory();
  const toast = useContext(ToastContext);
  const [postState, setPostState] = useState<IPostOpgaveListeState>({
    state: "IDLE",
  });
  const [artikelListState, setArtikelListState] = useState<IArtikelListState>({
    state: "IDLE",
  });
  const [opgaveListeState, setOpgaveListeState] = useState<StrippedOpgaveListe>(
    {
      artikelId: "",
      navn: "",
      beskrivelse: "",
      listetype: undefined,
      status: undefined,
      brugerstatus: [{ status: 5, visesUi: false, navn: "" }],
      kommentarType: ListeKommentarType.ingen,
    }
  );

  const [disabled, setDisabled] = useState(true); // Disable the form button if form is not validated
  const { listeId } = props; // If editing an already existing list, this will be !== undefined

  // TODO: Check if this list grows large, because then "just" a dropdown will not be feasible
  const populateArtikelList = () => {
    setArtikelListState({ state: "LOADING" });
    const fetchData = async () => {
      artikelApi
        .getAll({ page: 1, pageSize: 1000, trimindhold: true })
        .then((resp) => {
          setArtikelListState({ data: resp, state: "OK" });
        })
        .catch((error) => {
          if (error === "CANCEL") {
            console.debug(error);
          } else {
            setArtikelListState({ state: "ERROR" });
            setPostState({
              state: "ERROR",
              data: {
                statusText: "Kunne ikke hente artikler ...",
                status: "error",
              },
            });
          }
        });
    };
    fetchData();
  };
  if (
    artikelListState.data === undefined &&
    artikelListState.state === "IDLE"
  ) {
    populateArtikelList();
  }

  const handleSubmit = (e) => {
    if (!disabled) {
      setPostState({ state: "LOADING" });

      // We post a new liste.
      if (listeId === undefined) {
        opgavelisteApi
          .createListe(opgaveListeState)
          .then(async (resp) => {
            listeOrganisationerState.data.forEach(async (org) => {
              try {
                await opgavelisteApi.addListeAdgang(resp.id, org.id);
              } catch (error) {
                setPostState({ state: "ERROR", data: error });
              }
            });
            try {
              const kanIkkeLøsesBrugerStatus =
                opgaveListeState.brugerstatus.find((a) => a.status === 5);
              if (
                kanIkkeLøsesBrugerStatus &&
                kanIkkeLøsesBrugerStatus.visesUi
              ) {
                await opgavelisteApi.addBrugerStatus(resp.id, 5);
              } else {
                await opgavelisteApi.removeBrugerStatus(resp.id, 5);
              }
            } catch (error) {
              setPostState({ state: "ERROR", data: error });
              throw error;
            }
            toast({
              status: "OK",
              text: `Listen ${resp.navn} blev oprettet`,
              show: true,
            });
            history.replace(
              `/admin?type=lister&handling=rediger&listeid=${resp.id}`
            );
          })
          .catch((error) => {
            if (error === "CANCEL") {
              console.debug(error);
            } else {
              toast({
                status: "ERROR",
                text: `Noget gik galt når listen ${opgaveListeState.navn} blev oprettet.`,
                show: true,
              });
              setPostState({ state: "ERROR", data: error });
            }
          });
      } else {
        opgavelisteApi
          .updateListe(opgaveListeState, listeId)
          .then(async (resp) => {
            try {
              const adgange = await opgavelisteApi.getListeAdgange(resp.id);
              const adgangeToRemove = adgange.filter(
                (o) =>
                  listeOrganisationerState.data.findIndex(
                    (lo) => lo.id === o.id
                  ) === -1
              );
              const adgangeToAdd = listeOrganisationerState.data.filter(
                (o) => adgange.findIndex((lo) => lo.id === o.id) === -1
              );
              adgangeToRemove.forEach(async (adgang) => {
                try {
                  await opgavelisteApi.removeListeAdgang(listeId, adgang.id);
                } catch (error) {
                  // const errorText = `Listen ${opgaveListeState.navn} kunne ikke redigeres: ${error.statusText}, status: ${error.status}`;
                  const errorText = `Listen ${opgaveListeState.navn} kunne ikke redigeres`;
                  toast({
                    status: "ERROR",
                    text: errorText,
                    show: true,
                  });
                  setPostState({ state: "ERROR", data: error });
                }
              });
              adgangeToAdd.forEach(async (adgang) => {
                try {
                  await opgavelisteApi.addListeAdgang(listeId, adgang.id);
                } catch (error) {
                  // const errorText = `Listen ${opgaveListeState.navn} kunne ikke redigeres: ${error.statusText}, status: ${error.status}`;
                  const errorText = `Listen ${opgaveListeState.navn} kunne ikke redigeres`;
                  toast({
                    status: "ERROR",
                    text: errorText,
                    show: true,
                  });
                  setPostState({ state: "ERROR", data: error });
                }
              });

              try {
                const oldOpgaveListe = await opgavelisteApi.getListeFromId(
                  listeId
                );
                oldOpgaveListe.brugerstatus.forEach(
                  async (currentBrugerStatus) => {
                    try {
                      const newStatus = opgaveListeState.brugerstatus.find(
                        (bs) => bs.status === currentBrugerStatus.status
                      );
                      if (
                        newStatus &&
                        newStatus.visesUi !== currentBrugerStatus.visesUi
                      ) {
                        if (newStatus.visesUi) {
                          await opgavelisteApi.addBrugerStatus(
                            listeId,
                            currentBrugerStatus.status
                          );
                        } else {
                          await opgavelisteApi.removeBrugerStatus(
                            listeId,
                            currentBrugerStatus.status
                          );
                        }
                      }
                    } catch (error) {
                      throw error;
                    }
                  }
                );
              } catch (error) {
                // const errorText = `Listen ${opgaveListeState.navn} kunne ikke redigeres: ${error.statusText}, status: ${error.status}`;
                const errorText = `Listen ${opgaveListeState.navn} kunne ikke redigeres`;
                toast({
                  status: "ERROR",
                  text: errorText,
                  show: true,
                });
                setPostState({ state: "ERROR", data: error });
                throw error;
              }

              toast({
                status: "OK",
                text: `Listen ${opgaveListeState.navn} blev redigeret`,
                show: true,
              });
              setPostState({ state: "OK", data: resp });
            } catch (error) {
              // const errorText = `Listen ${opgaveListeState.navn} kunne ikke redigeres: ${error.statusText}, status: ${error.status}`;
              const errorText = `Listen ${opgaveListeState.navn} kunne ikke redigeres`;
              toast({
                status: "ERROR",
                text: errorText,
                show: true,
              });
              setPostState({ state: "ERROR", data: error });
            }
          })
          .catch((error) => {
            if (error === "CANCEL") {
              console.debug(error);
            } else {
              setPostState({ state: "ERROR", data: error });
            }
          });
      }
    }
    e?.preventDefault();
  };

  useEffect(() => {
    setDisabled(formValidation());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [opgaveListeState]);

  useEffect(() => {
    let stillMounted = true;
    const fetchData = async () => {
      setPostState({ state: "LOADING" }); // Might be redundant state update if state starts at loading
      if (listeId !== undefined) {
        try {
          const ol = await opgavelisteApi.getListeFromId(listeId);
          if (stillMounted) {
            setPostState({ state: "IDLE" });
            // Dette tæller som en 204 NO content.
            if (typeof ol === "string") {
              toast({
                status: "ERROR",
                text: `Kunne ikke finde opgavelisten med id: ${listeId}`,
                show: true,
              });
              setPostState({
                state: "ERROR",
                data: {
                  status: "error",
                  statusText: `Kunne ikke finde opgavelisten med id: ${listeId}`,
                },
              });
            } else {
              // Destructure the object by removing antalOpgaver
              let { antalOpgaver, ...picked_ol } = ol;
              setOpgaveListeState({ ...picked_ol });
            }
          }
        } catch (error) {
          if (error === "CANCEL") {
            // The request was canceled, all is well
            console.debug(error);
          } else {
            if (stillMounted) {
              // Request failed, set error state
              setPostState({
                state: "ERROR",
                data: {
                  status: "error",
                  statusText: `Kunne ikke finde opgavelisten med id: ${listeId}`,
                },
              });
              setPostState({
                state: "ERROR",
                data: {
                  status: "error",
                  statusText: `Kunne ikke finde opgavelisten med id: ${listeId}`,
                },
              });
            }
          }
        }
      }
    };
    fetchData();
    return () => {
      // The return function is called when
      // the component unmounts. We use the
      // stillmounted flag to avoid attempting
      // to set state on an unmounted component
      stillMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listeId]);

  // here we run any validation
  const formValidation = () => {
    // Temp flag
    if (opgaveListeState) {
      for (let key in opgaveListeState) {
        if (
          (opgaveListeState[key] === undefined ||
            opgaveListeState[key] === "") &&
          key !== "identifikation"
        ) {
          return true;
        }
      }
    }
    return false;
  };

  const [organisationerState, setOrganisationerState] =
    useState<IOrganisationState>({
      state: "IDLE",
      data: [],
    });
  useEffect(() => {
    let stillMounted = true;
    const fetchData = async () => {
      setOrganisationerState({
        state: "LOADING",
        data: [],
      });
      try {
        const organisationer = await organisationApi.getAll({
          page: 1,
          pageSize: 9999,
        });

        if (stillMounted) {
          setOrganisationerState({
            data: organisationer,
            state: "IDLE",
          });
        }
      } catch (error) {
        if (error === "CANCEL") {
          console.debug(error);
        } else {
          if (stillMounted) {
            setOrganisationerState({ state: "ERROR", data: [] });
          }
        }
      }
    };
    fetchData();
  }, []);

  const [listeOrganisationerState, setListeOrganisationerState] =
    useState<IOrganisationState>({
      state: "IDLE",
      data: [],
    });
  useEffect(() => {
    let stillMounted = true;
    const fetchData = async (id) => {
      setListeOrganisationerState({
        state: "LOADING",
        data: [],
      });
      try {
        const resp = await opgavelisteApi.getListeAdgange(id);

        if (stillMounted) {
          setListeOrganisationerState({
            data: resp,
            state: "IDLE",
          });
        }
      } catch (error) {
        if (error === "CANCEL") {
          console.debug(error);
        } else {
          if (stillMounted) {
            setListeOrganisationerState({ state: "ERROR", data: [] });
          }
        }
      }
    };
    if (listeId) {
      fetchData(listeId);
    } else {
      setListeOrganisationerState({
        data: [],
        state: "IDLE",
      });
    }
  }, [listeId]);

  const kanIkkeLøses = opgaveListeState.brugerstatus.find(
    (b) => b.status === 5
  );
  const setBrugerStatusUIState = (status: number, visesIUI: boolean) => {
    let newBrugerStatus = [...opgaveListeState.brugerstatus];
    let updatedStatus = newBrugerStatus.find((bs) => bs.status === status);
    if (!updatedStatus) return setOpgaveListeState(opgaveListeState);
    updatedStatus.visesUi = visesIUI;
    return setOpgaveListeState({
      ...opgaveListeState,
      brugerstatus: [...newBrugerStatus],
    });
  };
  const setOpgavelisteKommentartype = (type: ListeKommentarType) => {
    return setOpgaveListeState({
      ...opgaveListeState,
      kommentarType: type,
    });
  };

  return (
    <div className={styles.container}>
      {postState.state !== "ERROR" && (
        <Fragment>
          {listeId === undefined && <h1>Opret ny liste</h1>}
          {listeId !== undefined && (
            <h1>Redigerer listen: {opgaveListeState.navn}</h1>
          )}

          <form onSubmit={handleSubmit}>
            <div className="flex flex-gap">
              <div className={styles.flexitem_2}>
                <label className="h5" htmlFor="titel">
                  Titel
                </label>
                <input
                  type="text"
                  id="titel"
                  name="titel"
                  placeholder="F.ex. Arealafvigelser"
                  className="rounded"
                  required
                  value={opgaveListeState.navn}
                  onChange={(e) =>
                    setOpgaveListeState({
                      ...opgaveListeState,
                      navn: e.target.value,
                    })
                  }
                />
              </div>
              <div className={styles.flexitem_1}>
                <label className="h5" htmlFor="identifikation">
                  Identifikation
                </label>
                <input
                  type="text"
                  id="identifikation"
                  name="identifikation"
                  placeholder="F. ex. 37"
                  className="rounded"
                  value={
                    opgaveListeState.identifikation
                      ? opgaveListeState.identifikation
                      : ""
                  }
                  onChange={(e) =>
                    setOpgaveListeState({
                      ...opgaveListeState,
                      identifikation: e.target.value
                        ? e.target.value
                        : undefined,
                    })
                  }
                />
              </div>
            </div>
            <label className="h5" htmlFor="beskrivelse">
              Beskrivelse
            </label>
            <input
              type="text"
              id="beskrivelse"
              name="beskrivelse"
              placeholder="F. ex. Bygninger med fejl i arealsummering"
              className="rounded"
              required
              value={opgaveListeState.beskrivelse}
              onChange={(e) =>
                setOpgaveListeState({
                  ...opgaveListeState,
                  beskrivelse: e.target.value,
                })
              }
            />
            <label className="h5" htmlFor="link">
              Artikel
            </label>
            <div className={styles.selectbox}>
              <select
                id="artikel"
                name="artikel"
                placeholder="Vælg kategori ..."
                className="rounded"
                value={opgaveListeState.artikelId}
                onChange={(e) => {
                  setOpgaveListeState({
                    ...opgaveListeState,
                    artikelId: e.target.value,
                  });
                }}
              >
                <option value="" disabled>
                  Vælg artikel ...
                </option>
                {artikelListState.data !== undefined &&
                  artikelListState.data.map((artikel, idx) => {
                    return (
                      <option key={artikel.id} value={artikel.id}>
                        {artikel.titel}
                      </option>
                    );
                  })}
              </select>
              <span className={styles.arrowdown}>
                <ChevronRight />
              </span>
            </div>

            {/* <div className="flex">
              <fieldset className={styles.prioritet}>
                <legend>Prioritet</legend>
                <p>
                  <label className={styles.check}>
                    Skal løses
                    <input
                      type="radio"
                      name="radio-1"
                      checked={
                        opgaveListeState.listetype === OpgaveListeType.skal
                          ? true
                          : false
                      }
                      onChange={(e) => {
                        setOpgaveListeState({
                          ...opgaveListeState,
                          listetype: OpgaveListeType.skal,
                        });
                      }}
                    />
                    <span className={styles.radiobutton}></span>
                  </label>
                  <label className={styles.check}>
                    Bør løses
                    <input
                      type="radio"
                      name="radio-1"
                      checked={
                        opgaveListeState.listetype === OpgaveListeType.kan
                          ? true
                          : false
                      }
                      onChange={(e) => {
                        setOpgaveListeState({
                          ...opgaveListeState,
                          listetype: OpgaveListeType.kan,
                        });
                      }}
                    />
                    <span className={styles.radiobutton}></span>
                  </label>
                </p>
              </fieldset>
              <fieldset>
                <legend>Status</legend>
                <p>
                  <label className={styles.check}>
                    Aktiv
                    <input
                      type="radio"
                      name="radio-2"
                      checked={
                        opgaveListeState.status === OpgaveListeStatus.aktiv
                          ? true
                          : false
                      }
                      onChange={(e) => {
                        setOpgaveListeState({
                          ...opgaveListeState,
                          status: OpgaveListeStatus.aktiv,
                        });
                      }}
                    />
                    <span className={styles.radiobutton}></span>
                  </label>
                  <label className={styles.check}>
                    Passiv
                    <input
                      type="radio"
                      name="radio-2"
                      checked={
                        opgaveListeState.status === OpgaveListeStatus.passiv
                          ? true
                          : false
                      }
                      onChange={(e) => {
                        setOpgaveListeState({
                          ...opgaveListeState,
                          status: OpgaveListeStatus.passiv,
                        });
                      }}
                    />
                    <span className={styles.radiobutton}></span>
                  </label>
                  <label className={styles.check}>
                    Udgået
                    <input
                      type="radio"
                      name="radio-2"
                      checked={
                        opgaveListeState.status === OpgaveListeStatus.udgaaet
                          ? true
                          : false
                      }
                      onChange={(e) => {
                        setOpgaveListeState({
                          ...opgaveListeState,
                          status: OpgaveListeStatus.udgaaet,
                        });
                      }}
                    />
                    <span className={styles.radiobutton}></span>
                  </label>
                </p>
              </fieldset>
            </div> */}
            <div className="container">
              <div className="row">
                <div className="col">
                  <label className="h5 mb-1">Prioritet</label>
                  <div className="d-flex gap-5 px-2">
                    <div className="form-check ">
                      <input
                        className="form-check-input cursor-pointer"
                        type="radio"
                        name="radio-1"
                        id="skalloeses"
                        checked={
                          opgaveListeState.listetype === OpgaveListeType.skal
                            ? true
                            : false
                        }
                        onChange={(e) => {
                          setOpgaveListeState({
                            ...opgaveListeState,
                            listetype: OpgaveListeType.skal,
                          });
                        }}
                      />
                      <label
                        className="form-check-label cursor-pointer"
                        htmlFor="skalloeses"
                      >
                        Skal løses
                      </label>
                    </div>
                    <div className="form-check ">
                      <input
                        className="form-check-input cursor-pointer"
                        type="radio"
                        name="radio-1"
                        id="kanloeses"
                        checked={
                          opgaveListeState.listetype === OpgaveListeType.kan
                            ? true
                            : false
                        }
                        onChange={(e) => {
                          setOpgaveListeState({
                            ...opgaveListeState,
                            listetype: OpgaveListeType.kan,
                          });
                        }}
                      />
                      <label
                        className="form-check-label cursor-pointer"
                        htmlFor="kanloeses"
                      >
                        Bør løses
                      </label>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <label className="h5 mb-1">Status</label>
                  <div className="d-flex gap-5 px-2">
                    <div className="form-check ">
                      <input
                        className="form-check-input cursor-pointer"
                        type="radio"
                        name="radio-2"
                        id="aktiv"
                        checked={
                          opgaveListeState.status === OpgaveListeStatus.aktiv
                            ? true
                            : false
                        }
                        onChange={(e) => {
                          setOpgaveListeState({
                            ...opgaveListeState,
                            status: OpgaveListeStatus.aktiv,
                          });
                        }}
                      />
                      <label
                        className="form-check-label cursor-pointer"
                        htmlFor="aktiv"
                      >
                        Aktiv
                      </label>
                    </div>
                    <div className="form-check ">
                      <input
                        className="form-check-input cursor-pointer"
                        type="radio"
                        name="radio-2"
                        id="passiv"
                        checked={
                          opgaveListeState.status === OpgaveListeStatus.passiv
                            ? true
                            : false
                        }
                        onChange={(e) => {
                          setOpgaveListeState({
                            ...opgaveListeState,
                            status: OpgaveListeStatus.passiv,
                          });
                        }}
                      />
                      <label
                        className="form-check-label cursor-pointer"
                        htmlFor="passiv"
                      >
                        Passiv
                      </label>
                    </div>
                    <div className="form-check ">
                      <input
                        className="form-check-input cursor-pointer"
                        type="radio"
                        name="radio-2"
                        id="udgaaet"
                        checked={
                          opgaveListeState.status === OpgaveListeStatus.udgaaet
                            ? true
                            : false
                        }
                        onChange={(e) => {
                          setOpgaveListeState({
                            ...opgaveListeState,
                            status: OpgaveListeStatus.udgaaet,
                          });
                        }}
                      />
                      <label
                        className="form-check-label cursor-pointer"
                        htmlFor="udgaaet"
                      >
                        Udgået
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Spacing top={1} />
            <div>
              <label htmlFor="" className="h5 mb-3">
                Kommentarfelt på opgaver
              </label>
              <div className="">
                <div className="form-check ps-0 d-flex align-items-center">
                  <label
                    className="form-check-label"
                    htmlFor="skatteforvaltningen"
                  >
                    For Skatteforvaltningen
                  </label>
                  <input
                    className="form-check-input d-inline-block ms-3 mb-1"
                    type="checkbox"
                    value=""
                    id="skatteforvaltningen"
                    name="forside"
                    checked={
                      opgaveListeState.kommentarType ===
                      ListeKommentarType.skatteforvaltningen
                    }
                    onChange={() => {
                      setOpgavelisteKommentartype(
                        opgaveListeState.kommentarType ===
                          ListeKommentarType.skatteforvaltningen
                          ? ListeKommentarType.ingen
                          : ListeKommentarType.skatteforvaltningen
                      );
                    }}
                  />
                </div>
              </div>
            </div>
            {/* <div className="flex">
              <fieldset>
                <legend>Kommentarfelt på opgaver</legend>
                <label className={styles.check} style={{ paddingLeft: 0 }}>
                  <span className={styles.checkboxlabel}>
                    For Skatteforvaltningen
                  </span>
                  <input
                    type="checkbox"
                    name="forside"
                    checked={
                      opgaveListeState.kommentarType ===
                      ListeKommentarType.skatteforvaltningen
                    }
                    onChange={() => {
                      setOpgavelisteKommentartype(
                        opgaveListeState.kommentarType ===
                          ListeKommentarType.skatteforvaltningen
                          ? ListeKommentarType.ingen
                          : ListeKommentarType.skatteforvaltningen
                      );
                    }}
                  />
                  <span className={styles.checkbox}></span>
                </label>
              </fieldset>
            </div> */}
            <Spacing top={1} />
            <div>
              <label htmlFor="" className="h5 mb-3">
                Valgfrie brugerstatuser for listens opgaver
              </label>
              <div className="">
                <div className="form-check ps-0 d-flex align-items-center">
                  <label className="form-check-label" htmlFor="valgfri">
                    Kan ikke løses
                  </label>
                  {kanIkkeLøses && (
                    <input
                      className="form-check-input d-inline-block ms-3 mb-1"
                      type="checkbox"
                      value=""
                      id="valgfri"
                      name="forside"
                      checked={kanIkkeLøses.visesUi}
                      onChange={() => {
                        if (kanIkkeLøses) {
                          setBrugerStatusUIState(5, !kanIkkeLøses.visesUi);
                        }
                      }}
                    />
                  )}
                  {!kanIkkeLøses && (
                    <input
                      disabled={kanIkkeLøses === undefined}
                      className="form-check-input d-inline-block ms-3 mb-1"
                      type="checkbox"
                      name="forside"
                      id="valgfri"
                    />
                  )}
                </div>
              </div>
            </div>
            {/* <div className="flex">
              <fieldset>
                <legend>Valgfrie brugerstatuser for listens opgaver</legend>
                <label className={styles.check} style={{ paddingLeft: 0 }}>
                  <span className={styles.checkboxlabel}>Kan ikke løses</span>
                  {kanIkkeLøses && (
                    <input
                      type="checkbox"
                      name="forside"
                      checked={kanIkkeLøses.visesUi}
                      onChange={() => {
                        if (kanIkkeLøses) {
                          setBrugerStatusUIState(5, !kanIkkeLøses.visesUi);
                        }
                      }}
                    />
                  )}
                  {!kanIkkeLøses && (
                    <input
                      disabled={kanIkkeLøses === undefined}
                      type="checkbox"
                      name="forside"
                    />
                  )}

                  <span className={styles.checkbox}></span>
                </label>
              </fieldset>
            </div> */}
            <Spacing top={0.5} />
            <ListeAdgange
              listeOrganisationerState={listeOrganisationerState}
              organisationerState={organisationerState}
              setListeOrganisationerState={setListeOrganisationerState}
            />
            {listeId === undefined && (
              <input
                type="submit"
                value="Publicér liste"
                disabled={disabled !== undefined ? disabled : false}
              />
            )}
            {listeId !== undefined && (
              <input
                type="submit"
                value="Publicér ændringer"
                disabled={disabled !== undefined ? disabled : false}
              />
            )}
          </form>
        </Fragment>
      )}
    </div>
  );
}
interface IOpretListe {
  listeId?: string;
}
