import React, { useState, useEffect } from "react";
import styles from "./Ejer.module.scss";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
// import Arrow from "components/common/icons/Arrow";
// import { mergeClassNames } from "util/index";
// import FoldOutSection from "components/common/FoldOutSection";
import ejfApi from "api/ejf";
import { EjerforholdsKode } from "interfaces/ejf/enums";
import { EjendomState, dataIsEjenom } from "routes/Ejendom/Ejendom";
import DropDownSection from "components/DropDownSection";
import ArrowRight from "components/common/icons/ArrowRight";

export default function Ejer(props: IEjer) {
  const { ejendomState } = props;
  const ejendom = dataIsEjenom(ejendomState.data)
    ? ejendomState.data
    : undefined;
  const [ejfState, setEjfState] = useState<{
    state: "IDLE" | "LOADING" | "ERROR";
    primaer?: EJF.Ejerfortegnelse;
    ikke_primaer?: EJF.Ejerfortegnelse[];
  }>({ state: "LOADING" });

  const [sammeejerState, setSammeejerState] = useState<{
    state: "IDLE" | "LOADING" | "EMPTY" | "ERROR";
    bfenumre?: number[];
  }>({ state: "LOADING" });

  useEffect(() => {
    let stillMounted = true;
    setEjfState({ ...ejfState, state: "LOADING" });

    const fetchEjerfortegnelse = async (bfenummer) => {
      try {
        const ol = await ejfApi.getEjf(Number(bfenummer));
        if (ol) {
          // Find the PRIMARY owner - besluttet af #106 at have alle ejere med
          let primaer_ejer = ol.find((x) => x.primaerKontakt === true);
          let ikke_primaer_ejer = ol.filter((x) => x.primaerKontakt !== true);

          //let cvr = ol.ejendeVirksomhed.attributes.CVRNummer;
          if (primaer_ejer) {
            setEjfState({
              ...ejfState,
              state: "IDLE",
              primaer: primaer_ejer,
              ikke_primaer: ikke_primaer_ejer,
            });
          } else {
            setEjfState({ state: "ERROR" });
          }
        }
      } catch (error) {
        if (error === "CANCEL") {
          // The request was canceled, all is well
          console.debug(error);
        } else {
          if (stillMounted) {
            // Request failed, set error state
            setEjfState({ state: "ERROR" });
          }
        }
      }
    };
    const fetchSammeejer = async (bfenummer) => {
      try {
        const ol = await ejfApi.getEjfSammeejer(Number(bfenummer));
        if (ol) {
          let bfenumre = ol.filter((x) => x !== ejendom?.bfeNr).sort();
          if (bfenumre.length === 0) {
            setSammeejerState({ state: "EMPTY", bfenumre: bfenumre });
          } else {
            setSammeejerState({ state: "IDLE", bfenumre: ol.sort() });
          }
        }
      } catch (error) {
        if (error === "CANCEL") {
          // The request was canceled, all is well
          console.debug(error);
        } else {
          if (stillMounted) {
            // Request failed, set error state
            setSammeejerState({ state: "ERROR" });
          }
        }
      }
    };
    if (ejendomState.state === "IDLE" && ejendom) {
      fetchEjerfortegnelse(ejendom.bfeNr);
      fetchSammeejer(ejendom.bfeNr);
    } else if (ejendomState.state === "ERROR") {
      setEjfState({ state: "ERROR" });
      setSammeejerState({ state: "ERROR" });
    }
    return () => {
      // The return function is called when
      // the component unmounts. We use the
      // stillmounted flag to avoid attempting
      // to set state on an unmounted component
      stillMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ejendom, ejendomState]);

  const renderEjerOplysninger = (e) => {
    let navn: string = "",
      adresse: string = "",
      // TODO ejerforholdskode: number = e?.ejerforholdskode, // TODO: Med tilhørende kodeværdi
      cvr: number = 0;

    /* Navn */
    // Person
    if (e.ejendePerson) {
      navn = e.ejendePerson.Person.Navn
        ? e.ejendePerson.Person.Navn?.["adresseringsnavn"]
        : "Person med navne- og adressebeskyttelse.";
      // Virksomhed
    } else if (e.ejendeVirksomhed) {
      navn = e.ejendeVirksomhed.attributes.navn
        ? e.ejendeVirksomhed.attributes.navn
        : "Kunne ikke finde virksomhedens navn.";
      cvr = e.ejendeVirksomhed.attributes.CVRNummer;
      // Andet (foreninger m.m)
    } else if (e.ejeroplysninger?.features) {
      if (e.ejeroplysninger?.features.length === 1) {
        navn = e.ejeroplysninger?.features[0].properties.navn;
      } else if (e.ejeroplysninger?.features.length === 0) {
        // Empty array
        navn = "Ingen ejeroplysninger.";
      } else {
        navn = e.ejeroplysninger?.features[0].properties.navn + " m.m";
      }
    } else {
      navn = "Kunne ikke bestemme ejeroplysninger.";
    }
    /* Adresse */
    // Person
    if (e.ejendePerson) {
      if (e.ejendePerson.Person.CprAdresse === null) {
        adresse = "Adressen er beskyttet/skjult.";
      } else {
        adresse =
          e.ejendePerson.Person.Standardadresse +
          ", " +
          e.ejendePerson.Person.CprAdresse?.["postnummer"] +
          " " +
          e.ejendePerson.Person.CprAdresse?.["postdistrikt"];
      }

      // Virksomhed
    } else if (e.ejendeVirksomhed) {
      let bl = e.ejendeVirksomhed.attributes?.beliggenhedsadresse;
      if (bl) {
        adresse =
          bl["CVRAdresse.vejnavn"] +
          " " +
          bl["CVRAdresse.husnummerFra"] +
          ", " +
          bl["CVRAdresse.postnummer"] +
          " " +
          bl["CVRAdresse.postdistrikt"];
      }
      // Andet, foreninger?
    } else if (e.ejeroplysninger?.features) {
      if (e.ejeroplysninger?.features.length === 1) {
        adresse =
          e.ejeroplysninger?.features[0].properties?.alternativAdresse
            ?.adresselinje1 +
          " " +
          e.ejeroplysninger?.features[0].properties?.alternativAdresse
            ?.adresselinje2;
      } else if (e.ejeroplysninger?.features.length === 0) {
        adresse = "Kunne ikke bestemme adressen.";
      } else {
        adresse =
          e.ejeroplysninger?.features[0].properties?.alternativAdresse
            ?.adresselinje1 + " ";
        let adrlinje2 =
          e.ejeroplysninger?.features[0].properties?.alternativAdresse
            ?.adresselinje2;
        if (adrlinje2 !== null) {
          adresse += adrlinje2;
        }
        adresse += " m.m";
      }
    } else {
      adresse = "Kunne ikke bestemme adressen.";
    }

    return (
      <p className={styles.ejer} key={navn}>
        <span>
          {navn !== undefined && navn ? navn : "Kunne ikke hente ejer."}
        </span>
        <span>
          {adresse !== undefined && adresse
            ? adresse
            : "Kunne ikke hente adresse."}
        </span>
        <span>{cvr !== 0 && "CVR: " + cvr}</span>
      </p>
    );
  };

  return (
    <div>
      <h5 className="fw-semibold text-body">Ejer</h5>
      {ejfState.state === "IDLE" && (
        <>
          <p className={styles.ejer}>
            {renderEjerOplysninger(ejfState.primaer)}
            {ejfState.ikke_primaer?.map((e) => {
              return renderEjerOplysninger(e);
            })}
          </p>
          <div className="mt-4">
            <h5 className="fw-semibold text-body mb-1">Ejerforhold</h5>
            <p className={styles.ejerforhold}>
              {ejfState.primaer?.ejerforholdskode &&
                // "Ejerforhold: "
                +ejfState.primaer.ejerforholdskode +
                  ", " +
                  EjerforholdsKode[ejfState.primaer.ejerforholdskode]}
            </p>
          </div>
        </>
      )}
      {ejfState.state === "LOADING" &&
        Array.from(new Array(3)).map((o, i) => {
          return (
            <motion.li key={i} className={`${styles.ejer} ${styles.loading}`}>
              <div className={styles.text}>
                <div
                  style={{ width: 30 + Math.random() * 70 + "%" }}
                  className={styles.line}
                ></div>
              </div>
            </motion.li>
          );
        })}
      {ejfState.state === "ERROR" && (
        <div className={styles.fejl}>
          <p>Kunne ikke hente ejeroplysninger.</p>
        </div>
      )}
      {sammeejerState.state === "IDLE" && (
        <DropDownSection
          nestingLevel={3}
          element={
            <h5 className="{styles.foldheading} fs-6 text-semibold d-flex align-items-center gap-2">
              Ejendomme med samme ejer:{" "}
              {sammeejerState.bfenumre?.length ? (
                <span className="c-avatar c-avatar--xs bg-body-tertiary px-2">
                  {sammeejerState.bfenumre?.length}
                </span>
              ) : (
                <span className="c-avatar c-avatar--xs bg-body-tertiary px-2">
                  0
                </span>
              )}
            </h5>
          }
        >
          <ul className="{styles.sammeejere} p-2 d-flex flex-column gap-2">
            {sammeejerState.bfenumre?.map((bfenr) => {
              return (
                <li key={bfenr}>
                  <Link
                    className="bg-body-tertiary px-4 py-2 rounded d-flex align-items-center justify-content-between hover-text-primary hover-primary-svg"
                    to={`/ejendom?bfe=${bfenr}`}
                  >
                    BFE: {bfenr} <ArrowRight />
                  </Link>
                </li>
              );
            })}
          </ul>
        </DropDownSection>
      )}
    </div>
  );
}

// Interface
interface IEjer {
  ejendomState: EjendomState;
}
