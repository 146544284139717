import React, { useContext, useLayoutEffect, useState } from "react";
import { Prompt } from "react-router-dom";
import opgaveApi from "api/opgave";
import { AuthContext } from "context/AuthProvider";
// import DropDownMenu from "components/common/DropDownMenu";
import useMediaQuery from "hooks/useMediaQuery";
import Comment from "components/common/icons/Comment";
import StatusDialog from "components/common/StatusDialog/StatusDialog";

export default function Noter(props: KommentarFeltProps) {
  const { opgaveState, setOpgaveState, icon, title } = props;
  const authState = useContext(AuthContext);
  const matches = useMediaQuery("(max-width: 1200px)");
  const matches500 = useMediaQuery("(max-width: 500px)");

  // Kommentartekst
  const [kommentarText, setKommentarText] = useState(
    opgaveState.data?.[opgaveState.idx]?.kommentar
  );
  const initBeforeUnLoad = (showExitPrompt) => {
    window.onbeforeunload = (event) => {
      // Show prompt based on state
      if (showExitPrompt) {
        // Nicolai: Det her fyrer faktisk onBlur eventet og gemmer teksten.
        // Hvis vi skal rette det skal vi implicit *ikke* gemme. Det kræver en showExitPrompt state.
        const e = event || window.event;
        e.preventDefault();
        if (e) {
          e.returnValue = "Hey";
        }
        return "";
      }
    };
    return true;
  };

  useLayoutEffect(() => {
    initBeforeUnLoad(
      kommentarText !== opgaveState.data?.[opgaveState.idx]?.kommentar
    );
  }, [kommentarText, opgaveState]);

  useLayoutEffect(() => {
    // opdater kommentar tekst dersom den er ændret via SignalR (anden bruger har ændret den f ex)
    setKommentarText(opgaveState.data?.[opgaveState.idx]?.kommentar);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [opgaveState.data?.[opgaveState.idx]?.kommentar]);

  const putKommentarText = async (
    id: string,
    kommentar: Opgave["kommentar"]
  ) => {
    try {
      opgaveApi
        .putKommentarText(
          id,
          opgaveState.data?.[opgaveState.idx]?.kommentarAdgang,
          kommentar,
          authState
        )
        .then((opgave: Opgave) => {
          let data = opgaveState.data;
          if (data) {
            data[opgaveState.idx] = opgave;
            setOpgaveState({
              ...opgaveState,
              data: data,
            });
          }
          initBeforeUnLoad(false);
          return data;
        });
    } catch (error) {
      if (error === "CANCEL") {
        // The request was canceled, all is well
        console.debug(error);
      } else {
        return undefined;
      }
    }
  };
  const areTextsEqual = (
    a: string | null | undefined,
    b: string | null | undefined
  ): boolean => {
    if (a === null || a === undefined) {
      a = "";
    }
    if (b === null || b === undefined) {
      b = "";
    }

    if (a === b) {
      return true;
    }

    return false;
  };
  return (
    <>
      <StatusDialog
        width={matches500 ? "100%" : "300px"}
        preventCloseOnSelect={true}
        activeButtonStyle={matches ? true : false}
        dropdownButtonStyle=""
        noArrow={true}
        element={
          <>
            {!matches && (
              <div className="d-flex align-items-center gap-2 px-3 py-3">
                {icon ? (
                  <span className="d-flex align-items-center pt-1 pe-1">
                    <Comment width={20} />
                  </span>
                ) : null}
                <p className="mb-0 text-muted fw-semibold fs-5">{title}</p>
              </div>
            )}
            {matches && (
              <span
                className="ps-2 pt-2 pe-2 pb-1 d-flex align-items-center justify-content-center rounded hover-light hover-primary-svg"
                style={{ width: "46px", height: "44px" }}
              >
                <span className="hover-svg">
                  <Comment width={23} />
                </span>
              </span>
            )}
          </>
        }
        menu={[
          <div
            key={opgaveState.idx}
            style={
              matches
                ? { width: "100%", height: "auto" }
                : { width: "300px", height: "auto" }
            }
            className="p-2 rounded"
          >
            <div>
              <textarea
                onBlur={(e) => {
                  opgaveState.data?.[opgaveState.idx].id &&
                    putKommentarText(
                      opgaveState.data?.[opgaveState.idx].id,
                      e.target.value
                    );
                }}
                className="w-100 rounded border-light text-muted fs-5 p-2"
                style={{ height: "12.5rem" }}
                placeholder="Indtast noter"
                value={kommentarText ? kommentarText : ""}
                onChange={(e) => {
                  setKommentarText(e.target.value);
                }}
              ></textarea>
            </div>
            <div className="fs-5 py-2 px-1" aria-live="polite">
              <span>
                <i>
                  Gemmer automatisk{" "}
                  {!areTextsEqual(
                    kommentarText,
                    opgaveState.data?.[opgaveState.idx]?.kommentar
                  )
                    ? "Du har ugemte ændringer"
                    : ""}
                </i>
                <i>
                  <Prompt
                    when={
                      !areTextsEqual(
                        kommentarText,
                        opgaveState.data?.[opgaveState.idx]?.kommentar
                      )
                    }
                    message="Du har ugemte ændringer, de gemmes hvis du fortsætter."
                  ></Prompt>
                </i>
              </span>
            </div>
          </div>,
        ]}
      />
      {/* <DropDownMenu
        width={matches500 ? "100%" : "300px"}
        preventCloseOnSelect={true}
        activeButtonStyle={matches ? true : false}
        dropdownButtonStyle=""
        noArrow={true}
        element={
          <>
            {!matches && (
              <div className="d-flex align-items-center gap-2 px-3 py-3">
                {icon ? (
                  <span className="d-flex align-items-center pt-1 pe-1">
                    <Comment width={17} />
                  </span>
                ) : null}
                <p className="mb-0 text-muted fw-medium fs-5">{title}</p>
              </div>
            )}
            {matches && (
              <span
                className="ps-2 pt-2 pe-2 pb-1 d-flex align-items-center justify-content-center rounded hover-light hover-primary-svg"
                style={{ width: "46px", height: "44px" }}
              >
                <span className="hover-svg">
                  <Comment width={23} />
                </span>
              </span>
            )}
          </>
        }
        menu={[
          <div
            key={opgaveState.idx}
            style={
              matches
                ? { width: "100%", height: "auto" }
                : { width: "300px", height: "auto" }
            }
            className="p-2 rounded"
          >
            <div>
              <textarea
                onBlur={(e) => {
                  opgaveState.data?.[opgaveState.idx].id &&
                    putKommentarText(
                      opgaveState.data?.[opgaveState.idx].id,
                      e.target.value
                    );
                }}
                className="w-100 rounded border-light text-muted fs-5 p-2"
                style={{ height: "12.5rem" }}
                placeholder="Indtast noter"
                value={kommentarText ? kommentarText : ""}
                onChange={(e) => {
                  setKommentarText(e.target.value);
                }}
              ></textarea>
            </div>
            <div className="fs-5 py-2 px-1" aria-live="polite">
              <span>
                <i>
                  Gemmer automatisk{" "}
                  {!areTextsEqual(
                    kommentarText,
                    opgaveState.data?.[opgaveState.idx]?.kommentar
                  )
                    ? "Du har ugemte ændringer"
                    : ""}
                </i>
                <i>
                  <Prompt
                    when={
                      !areTextsEqual(
                        kommentarText,
                        opgaveState.data?.[opgaveState.idx]?.kommentar
                      )
                    }
                    message="Du har ugemte ændringer, de gemmes hvis du fortsætter."
                  ></Prompt>
                </i>
              </span>
            </div>
          </div>,
        ]}
      /> */}
    </>
  );
}

interface KommentarFeltProps {
  opgaveState: {
    state: "LOADING" | "IDLE" | "ERROR";
    data: any;
    error: any;
    idx: number;
  };
  setOpgaveState: Function;
  icon?: boolean;
  title: string;
}
