import React, { useCallback, useMemo, useState } from "react";
import styles from "../../../Statistik.module.scss";
import { Spacing } from "components/common/layout/Spacing";
import statistikkApi from "api/statistik";
import { usePromise } from "hooks/usePromise";
import { mergeClassNames } from "util/index";
import { Bar } from "react-chartjs-2";

interface IAfsluttedeProps {
  type: "totalt" | "egne";
}
export default function Afsluttede(props: IAfsluttedeProps) {
  const { type } = props;
  const [vis, setVis] = useState<"dage" | "uger">("dage");
  const fetchListeStatus = useCallback(() => {
    if (type === "totalt") statistikkApi.getAfsluttedeTotalt();
    else statistikkApi.getAfsluttedeEgne();
  }, [type]);
  const listeStatusDefault = useMemo(() => {
    return [];
  }, []);
  const listeStatusState = usePromise(
    fetchListeStatus,
    listeStatusDefault
  ).state;
  function prepare(data: any[]) {
    const label =
      vis === "dage"
        ? "Afsluttede seneste 14 dage"
        : "Afsluttede seneste 14 uger";
    const filtered = data.filter((d) => d.navn === label);
    return {
      labels: filtered.map((f) => f.intervalNavn),
      datasets: [
        {
          label: "Antal afsluttede opgaver",
          data: filtered.map((f) => f.antal),
          backgroundColor: "#58816E",
        },
      ],
    };
  }
  return (
    <div
      className={mergeClassNames(
        styles.statcontainer,
        listeStatusState.state === "LOADING" ? styles.loading : ""
      )}
    >
      <Spacing />
      <div className={styles.headerrow}>
        <h2>
          {type === "egne"
            ? "Afsluttede opgaver - egne"
            : "Afsluttede opgaver - totalt"}
        </h2>
        <div>
          <span className={styles.vis}>Vis:</span>
          <button
            className={mergeClassNames(
              styles.togglebutton,
              vis === "dage" ? styles.active : ""
            )}
            role="tab"
            aria-selected={vis === "dage"}
            onClick={() => setVis("dage")}
          >
            Sidste 14 dage
          </button>
          <button
            className={mergeClassNames(
              styles.togglebutton,
              vis === "uger" ? styles.active : ""
            )}
            role="tab"
            aria-selected={vis === "uger"}
            onClick={() => setVis("uger")}
          >
            Sidste 14 uger
          </button>
        </div>
        <Spacing />
      </div>
      <Spacing />

      <Spacing />
      <div style={{ opacity: listeStatusState.state === "IDLE" ? 1 : 0 }}>
        {listeStatusState.state === "IDLE" && (
          <Bar
            options={{
              plugins: { legend: {} },
              scales: {
                y: {
                  beginAtZero: true,
                  ticks: {
                    precision: 0,
                  },
                },
              },
            }}
            data={prepare(listeStatusState.data)}
          />
        )}
      </div>
    </div>
  );
}
