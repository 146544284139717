import React, { useState, useEffect, Fragment } from "react";
import styles from "./Grund.module.scss";
import GrundIcon from "components/common/icons/Grund";
import WarningLabel from "components/common/PriorityLabels/WarningLabel";
import ExpandablePaper from "components/common/ExpandablePaper";
import GrundInfo from "./GrundInfo";
import Bygning from "./Bygning/Bygning";
import TekniskAnlaeg from "./TekniskAnlaeg/TekniskAnlaeg";
import { usePrevious } from "hooks/usePrevious";

export default function Grund(props: IGrundProps) {
  const {
    grund,
    liste,
    opgave,
    expand,
    supplerendeOplysninger,
    title,
    showOpgaver,
  } = props;

  const [grundMarkeret, setGrundMarkeret] = useState(false);
  const [grundOpen, setGrundOpen] = useState(false);

  const prevOpgave = usePrevious(opgave);

  useEffect(() => {
    if (expand && grundMarkeret) {
      setGrundOpen(true);
    } else {
      setGrundOpen(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expand, grundMarkeret]);

  useEffect(() => {
    if (opgave && prevOpgave && opgave.id !== prevOpgave.id) {
      setGrundMarkeret(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [opgave]);

  const constructGrundSubtitle = () => {
    let bygningText = "";
    let tekniskAnlaegText = "";
    switch (grund.bygninger.length) {
      case 0:
        bygningText = "Ingen bygninger";
        break;
      case 1:
        bygningText = "1 bygning";
        break;
      default:
        bygningText = grund.bygninger.length + " bygninger";
    }
    switch (grund.tekniskeAnlaeg.length) {
      case 0:
        tekniskAnlaegText = ""; //"ingen tekniske anlæg";
        break;
      case 1:
        tekniskAnlaegText = ", 1 teknisk anlæg";
        break;
      default:
        tekniskAnlaegText =
          ", " + grund.tekniskeAnlaeg.length + " tekniske anlæg";
    }
    return `
     ${bygningText} ${tekniskAnlaegText}`;
  };

  return (
    <div className={styles.container}>
      <div>
        <ExpandablePaper
          headerIcon={<GrundIcon />}
          opgaveIcon={
            grundMarkeret && showOpgaver ? <WarningLabel /> : undefined
          }
          title={title}
          subtitle={constructGrundSubtitle()}
          nestingLevel={1}
          defaultMenuOpen={false}
          state={{ menuOpen: grundOpen, setMenuOpen: setGrundOpen }}
        >
          <GrundInfo
            grund={grund.grund}
            liste={liste}
            opgave={opgave}
            setGrundMarkeret={setGrundMarkeret}
            supplerendeOplysninger={supplerendeOplysninger}
          ></GrundInfo>
        </ExpandablePaper>
      </div>
      {grundOpen && (
        <div>
          <BygList
            grund={grund}
            expand={expand}
            setGrundMarkeret={setGrundMarkeret}
            liste={liste}
            opgave={opgave}
            showOpgaver={showOpgaver}
            supplerendeOplysninger={supplerendeOplysninger}
          />
        </div>
      )}
      {
        // Because we need to evaluate if bygninger or enheder has opgaver
        // We just hide them under the grund until we know.
      }
      {!grundOpen && (
        <div className={styles.hidden}>
          <BygList
            grund={grund}
            expand={expand}
            setGrundMarkeret={setGrundMarkeret}
            liste={liste}
            opgave={opgave}
            showOpgaver={showOpgaver}
            supplerendeOplysninger={supplerendeOplysninger}
          />
        </div>
      )}
    </div>
  );
}

function BygList(props: {
  grund: Grund;
  expand: boolean;
  setGrundMarkeret: any;
  liste?: OpgaveListe;
  opgave?: Opgave;
  supplerendeOplysninger?: SupplerendeOplysninger;
  showOpgaver: boolean;
}) {
  const {
    grund,
    expand,
    setGrundMarkeret,
    showOpgaver,
    liste,
    opgave,
    supplerendeOplysninger,
  } = props;
  const { bygninger, tekniskeAnlaeg } = grund;
  const tekniskeAnlaegUdenBygning = tekniskeAnlaeg.filter(
    (t) =>
      (t as any).tekniskAnlaeg.bygning === null &&
      (t as any).tekniskAnlaeg.enhed === null
  );
  return (
    <>
      {bygninger.map((bygning, idx) => {
        const ta = tekniskeAnlaeg.filter(
          (t) =>
            // teknisk anlæg knyttet til bygning #323
            (t as any).tekniskAnlaeg.bygning === bygning.bygning.id_lokalId ||
            // teknisk anlæg knyttet til enhed i bygning #502
            bygning.opgangList.find((opgang) => {
              return (
                (opgang as any).enhedList.find((enhed) => {
                  return (
                    enhed.enhed.id_lokalId === (t as any).tekniskAnlaeg.enhed
                  );
                }) !== undefined
              );
            })
        );

        return (
          <Fragment key={idx}>
            <Bygning
              key={bygning.bygning.id_lokalId}
              bygning={bygning}
              expand={expand}
              setGrundMarkeret={setGrundMarkeret}
              liste={liste}
              opgave={opgave}
              supplerendeOplysninger={supplerendeOplysninger}
              showOpgaver={showOpgaver}
            />
            {ta.map((tekanl, idx) => (
              <div className={styles.buildingNestingContainer}>
                <TekniskAnlaeg
                  key={idx}
                  tekniskAnlaeg={tekanl}
                  expand={expand}
                  setGrundMarkeret={setGrundMarkeret}
                  liste={liste}
                  opgave={opgave}
                  supplerendeOplysninger={supplerendeOplysninger}
                  showOpgaver={showOpgaver}
                  nestingLevel={3}
                />
              </div>
            ))}
          </Fragment>
        );
      })}
      {tekniskeAnlaegUdenBygning.map((ta, idx) => {
        return (
          <TekniskAnlaeg
            key={idx}
            tekniskAnlaeg={ta}
            expand={expand}
            setGrundMarkeret={setGrundMarkeret}
            liste={liste}
            opgave={opgave}
            supplerendeOplysninger={supplerendeOplysninger}
            showOpgaver={showOpgaver}
            nestingLevel={2}
          />
        );
      })}
    </>
  );
}

function arePropsEqual(prev, next) {
  if (prev.opgave && next.opgave) {
    if (prev.opgave.id !== next.opgave.id) {
      return false;
    } else {
      return true;
    }
  }
  return false;
}
export const MemoizedGrund = React.memo(Grund, arePropsEqual);

interface IGrundProps {
  supplerendeOplysninger?: SupplerendeOplysninger;
  grund: Grund;
  expand: boolean;
  liste?: OpgaveListe;
  opgave?: Opgave;
  title: string;
  showOpgaver: boolean;
}
