import React, { useState, useEffect, useContext } from "react";
import MainContainer from "components/common/layout/MainContainer";
import opgavelisteApi from "api/opgavelister";
import { useURLSearchParams } from "hooks/useURLSearchParams";
import {
  OpgaveListeStatus,
  OpgaveSortOrder,
  ListeSortOrder,
} from "interfaces/Opgaveliste/enums";
import { OpgaveListePageOptions } from "interfaces/paging";
import { useLocalStorage } from "hooks/useLocalStorage";
import { AuthContext } from "context/AuthProvider";
import List from "./MainContent/List";
import { SearchResultContext } from "context/SearchResultProvider";
import { Redirect } from "react-router-dom";
import Breadcrumbs from "./Breadcrumbs";

// enum CurrentAccordionOpen {
//   fejllister,
//   udgåede,
//   ingen,
// }

enum CurrentListOpen {
  fejllister,
  udgåede,
}

export default function Fejllister() {
  const authState = useContext(AuthContext);
  const { redirect } = useContext(SearchResultContext);
  const query = useURLSearchParams();
  const queryId = query.get("id");
  // const history = useHistory();

  const [fejllisterState, setFejllisterState] = useState<{
    state: "IDLE" | "LOADING" | "ERROR";
    data: Array<OpgaveListe>;
  }>({ state: "IDLE", data: [] });

  const [lsSortOrder, setLsSortOrder] = useLocalStorage<number>(
    "opgave-options-sort-order",
    OpgaveSortOrder.bfeAsc
  );

  const [lsBrugerStatus, setLsBrugerStatus] = useLocalStorage<
    undefined | string
  >("opgave-options-bruger-status", undefined);

  const [lsBrugerId, setLsBrugerId] = useLocalStorage<boolean>(
    "opgave-options-bruger-id",
    false
  );

  const [lsKommuneNr, setLsKommuneNr] = useLocalStorage<undefined | string>(
    "opgave-options-kommunenr",
    undefined
  );

  const [lsOpgavePage, setLsOgavePage] = useLocalStorage<
    undefined | { page: number; opgaveId: string }
  >("opgave-options-opgave-page", undefined);

  // const defaultOpgaveOptions: OpgaveListePageOptions = {
  //   id: "",
  //   page: 1,
  //   sortOrder: OpgaveSortOrder.bfeAsc,
  //   brugerId: undefined,
  //   brugerStatus: undefined,
  //   kommunenr: undefined,
  // };

  const [opgaveOptions, setOpgaveOptions] = useState<OpgaveListePageOptions>({
    id: "",
    page: 1,
    sortOrder: lsSortOrder,
    brugerId: lsBrugerId && authState.bruger ? authState.bruger.id : undefined,
    brugerStatus: lsBrugerStatus,
    kommunenr:
      authState.bruger && authState.bruger.erSkatteforvaltningen
        ? lsKommuneNr
        : undefined,
  });

  useEffect(() => {
    // Gem ændringer i sortorder, og filtre i localStorage,
    // så de kan bibeholdes på tvers af pageviews
    // e.g. gå ind på ejendom, og tryk "back" for at
    // komme tilbage til fejllisten, se issue #304
    setLsSortOrder(
      opgaveOptions.sortOrder
        ? parseInt(opgaveOptions.sortOrder)
        : OpgaveSortOrder.bfeAsc
    );
    setLsBrugerStatus(opgaveOptions.brugerStatus);
    setLsBrugerId(opgaveOptions.brugerId ? true : false);
    setLsKommuneNr(opgaveOptions.kommunenr);
  }, [
    setLsSortOrder,
    setLsBrugerStatus,
    setLsBrugerId,
    setLsKommuneNr,
    opgaveOptions.sortOrder,
    opgaveOptions.brugerId,
    opgaveOptions.brugerStatus,
    opgaveOptions.kommunenr,
  ]);

  useEffect(() => {
    if (
      opgaveOptions.id &&
      !(
        opgaveOptions.id === lsOpgavePage?.opgaveId &&
        opgaveOptions.page === lsOpgavePage?.page
      )
    ) {
      if (opgaveOptions.id) {
        setLsOgavePage({
          page: opgaveOptions.page,
          opgaveId: opgaveOptions.id,
        });
      } else {
        setLsOgavePage(undefined);
      }
    }
  }, [lsOpgavePage, setLsOgavePage, opgaveOptions.page, opgaveOptions.id]);
  let currentOpgaveliste: OpgaveListe | undefined;
  if (queryId) {
    currentOpgaveliste = fejllisterState.data.find((o) => o.id === queryId);
  }
  if (
    currentOpgaveliste &&
    (!opgaveOptions || opgaveOptions.id !== currentOpgaveliste.id)
  ) {
    if (opgaveOptions.brugerStatus) {
      const currentBrugerStatuser = opgaveOptions.brugerStatus.split(",");
      const legalBrugerStatuser = [
        "0", // 0 allways allowed, but visesUI false for some reason.
        ...currentOpgaveliste.brugerstatus
          .filter((s) => s.visesUi)
          .map((s) => s.status.toString()),
      ];
      const newBrugerStatuser = currentBrugerStatuser
        .filter((s) => legalBrugerStatuser.indexOf(s) > -1)
        .join(",");

      opgaveOptions.brugerStatus = newBrugerStatuser;
    }
    const page =
      lsOpgavePage && lsOpgavePage.opgaveId === currentOpgaveliste.id
        ? lsOpgavePage.page
        : 1;

    setOpgaveOptions({
      ...opgaveOptions,
      id: currentOpgaveliste.id,
      page,
    });
  }

  useEffect(() => {
    let stillMounted = true;
    const fetchData = async () => {
      setFejllisterState({ state: "LOADING", data: [] });
      try {
        // const ol = await opgavelisteApi.getAll();
        const ol = await opgavelisteApi.getAll({
          page: opgaveOptions.page,
          listeSortOrder: ListeSortOrder.NavnAsc,
          pageSize: 100000,
        });
        if (stillMounted) {
          setFejllisterState({ state: "IDLE", data: ol });
        }
      } catch (error) {
        if (error === "CANCEL") {
          // The request was canceled, all is well
          console.debug(error);
        } else {
          if (stillMounted) {
            // Request failed, set error state
            setFejllisterState({ state: "ERROR", data: [] });
          }
        }
      }
    };

    fetchData();

    return () => {
      // The return function is called when
      // the component unmounts. We use the
      // stillmounted flag to avoid attempting
      // to set state on an unmounted component
      stillMounted = false;
    };
  }, [opgaveOptions.page]);

  const [currentListOpen, setCurrentListOpen] = useState(
    CurrentListOpen.fejllister
  );

  return (
    <MainContainer>
      <Breadcrumbs authState={authState} />
      {redirect.state && <Redirect push to={redirect.path} />}
      {/* {result && (
        <>
          <p>Intet BFE-nummer fundet ved søgning.</p>
        </>
      )} */}
      {/* <Sidebar> */}
      <div className="d-flex border-0 border-bottom border-1">
        <button
          type="button"
          onClick={() => {
            setCurrentListOpen(CurrentListOpen.fejllister);
          }}
          className={`btn rounded-0 fw-medium text-center pt-2 pb-3  ${
            currentListOpen === CurrentListOpen.fejllister
              ? "text-primary border-0 border-primary border-bottom border-1 "
              : " text-muted border-0 border-bottom border-1"
          } `}
          style={{ minWidth: "150px", marginBottom: "-1px" }}
        >
          <h4 className="mb-0 fw-semibold pb-1">Fejllister</h4>
        </button>
        <button
          type="button"
          onClick={() => {
            setCurrentListOpen(CurrentListOpen.udgåede);
          }}
          className={`btn rounded-0 fw-medium text-center pt-2 pb-3 ${
            currentListOpen === CurrentListOpen.udgåede
              ? "text-primary border-0 border-primary border-bottom border-1 "
              : " text-muted border-0 border-bottom border-1"
          } `}
          style={{ minWidth: "150px", marginBottom: "-1px" }}
        >
          <h4 className="mb-0 fw-semibold pb-1">Udgåede lister</h4>
        </button>
      </div>
      <List
        isOpen={currentListOpen === CurrentListOpen.fejllister}
        fejllisterState={{
          ...fejllisterState,
          data: fejllisterState.data.filter(
            (o) => o.status === OpgaveListeStatus.aktiv
          ),
        }}
        currentOpgaveliste={currentOpgaveliste}
      />
      <List
        isOpen={currentListOpen === CurrentListOpen.udgåede}
        fejllisterState={{
          ...fejllisterState,
          data: fejllisterState.data.filter(
            (o) => o.status === OpgaveListeStatus.udgaaet
          ),
        }}
        currentOpgaveliste={currentOpgaveliste}
      />
      {/* <Accordion
        isOpen={currentAccordionOpen === CurrentAccordionOpen.fejllister}
        // toggleIsOpen={setOpgaveListeOpen}
        headerText=""
        fejllisterState={{
          ...fejllisterState,
          data: fejllisterState.data.filter(
            (o) => o.status === OpgaveListeStatus.aktiv
          ),
        }}
        currentOpgaveliste={currentOpgaveliste}
      /> */}
      {/* <Accordion
        isOpen={currentAccordionOpen === CurrentAccordionOpen.udgåede}
        // toggleIsOpen={setUdgåedeOpen}
        headerText=""
        fejllisterState={{
          ...fejllisterState,
          data: fejllisterState.data.filter(
            (o) => o.status === OpgaveListeStatus.udgaaet
          ),
        }}
        currentOpgaveliste={currentOpgaveliste}
      /> */}
      {/* </Sidebar> */}
      {/* <MainContent
        opgaveliste={currentOpgaveliste}
        opgaveOptions={opgaveOptions}
        defaultOpgaveOptions={defaultOpgaveOptions}
        setOpgaveOptions={setOpgaveOptions}
      /> */}
    </MainContainer>
  );
}

export interface OpgaveOptions {
  id: string;
  page: number;
  totalCount: number;
  sortOrder?: OpgaveSortOrder;
}
