import React from "react";
import styles from "./ChrOplysninger.module.scss";
import ExternalArrow from "components/common/icons/ExternalArrow";

export default function ChrOplysninger(props: IChrOplysningerProps) {
  const { chrOplysninger } = props;
  const { oplysninger } = chrOplysninger;
  return (
    <div className={styles.oplysning}>
      <h4>CHR Oplysninger</h4>
      {oplysninger.map((oplysning) => {
        const { chrEjendom } = oplysning;
        const { chrNummer, dyrearter, ophoerteDyrearter } = chrEjendom;
        return (
          <ul key={chrNummer} className={styles.chr}>
            <li className={styles.item}>
              <h5>{"chrnummer"}</h5>
              {chrNummer !== 0 && (
                <div id={"chrnummer"}>
                  <p>
                    <a
                      className="external"
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://chr.fvst.dk/"
                    >
                      <span>{chrNummer}</span>
                      <ExternalArrow />
                    </a>
                  </p>
                </div>
              )}
              {chrNummer === 0 && (
                <div>
                  <p>Adressen har ikke noget CHR nummer.</p>
                </div>
              )}
            </li>
            {dyrearter.length > 0 && (
              <li className={styles.title}>
                <h5>Dyrearter</h5>
                {dyrearter.map((o) => {
                  return (
                    <ul
                      className={styles.dyreart}
                      key={"dyreartKode" + o.dyreartKode}
                    >
                      <li className={styles.item}>
                        <h5>{"Virksomhedart"}</h5>
                        <div>
                          <p>{o.virksomhedartTekst}</p>
                        </div>
                      </li>
                      <li className={styles.item}>
                        <h5>{"Dyreart"}</h5>
                        <div>
                          <p>{o.dyreartTekst}</p>
                        </div>
                      </li>
                      <li className={styles.item}>
                        <h5>{"Antal besætninger"}</h5>
                        <div>
                          <p>{o.antalBesaetninger}</p>
                        </div>
                      </li>
                      <li className={styles.item}>
                        <h5>{"Antal i alt"}</h5>
                        <div>
                          <p>{o.antalIAlt}</p>
                        </div>
                      </li>
                      <li className={styles.item}>
                        <h5>{"Besætning ophørt"}</h5>
                        <div>
                          <p>{o.ophoert}</p>
                        </div>
                      </li>
                    </ul>
                  );
                })}
              </li>
            )}
            {dyrearter.length === 0 && (
              <li className={styles.title}>
                <h5>Ingen registrerede dyrearter</h5>
              </li>
            )}

            {ophoerteDyrearter.length > 0 && (
              <li className={styles.title}>
                <h5>Ophørte dyrearter</h5>
                {ophoerteDyrearter.map((o) => {
                  return (
                    <ul className={styles.ophoertdyreart} key={o.dyreartKode}>
                      <li className={styles.item}>
                        <h5>{"Virksomhedart"}</h5>
                        <div>
                          <p>{o.virksomhedartTekst}</p>
                        </div>
                      </li>
                      <li className={styles.item}>
                        <h5>{"Dyreart"}</h5>
                        <div>
                          <p>{o.dyreartTekst}</p>
                        </div>
                      </li>
                      <li className={styles.item}>
                        <h5>{"Antal besætninger"}</h5>
                        <div>
                          <p>{o.antalBesaetninger}</p>
                        </div>
                      </li>
                      <li className={styles.item}>
                        <h5>{"Antal i alt"}</h5>
                        <div>
                          <p>{o.antalIAlt}</p>
                        </div>
                      </li>
                      <li className={styles.item}>
                        <h5>{"Besætning ophørt"}</h5>
                        <div>
                          <p>{o.ophoert}</p>
                        </div>
                      </li>
                    </ul>
                  );
                })}
              </li>
            )}
            {ophoerteDyrearter.length === 0 && (
              <li className={styles.title}>
                <h5>Ingen ophørte dyrearter</h5>
              </li>
            )}
          </ul>
        );
      })}
    </div>
  );
}

interface IChrOplysningerProps {
  chrOplysninger: ChrOplysninger;
}
