import { OpgaveListePageOptions } from "interfaces/paging";
import React, { useState, createContext, useContext, useEffect } from "react";
import opgaveApi from "api/opgave";
import opgavelisteApi from "api/opgavelister";
import { AuthContext, AuthState } from "./AuthProvider";
import {
  IStatusCount,
  ITaskQueryContext,
} from "./interface/ITaskQueryProvider";
import { OpgaveSortOrder } from "interfaces/Opgaveliste/enums";
import { useLocalStorage } from "hooks/useLocalStorage";
import useRealTimeOpgaver from "hooks/useRealTimeOpgaver";

const TaskQueryProvider = ({
  children,
  id,
}: {
  children: React.ReactNode;
  id: string;
}) => {
  const authState = useContext(AuthContext);
  const [lsSortOrder, setLsSortOrder] = useLocalStorage<number>(
    "opgave-options-sort-order",
    OpgaveSortOrder.bfeAsc
  );
  const [lsBrugerId, setLsBrugerId] = useLocalStorage<boolean>(
    "opgave-options-bruger-id",
    false
  );
  const [lsBrugerStatus, setLsBrugerStatus] = useLocalStorage<
    undefined | string
  >("opgave-options-bruger-status", undefined);
  const [lsKommuneNr, setLsKommuneNr] = useLocalStorage<undefined | string>(
    "opgave-options-kommunenr",
    undefined
  );
  const [lsOpgavePage, setLsOpgavePage] = useLocalStorage<
    undefined | { page: number; opgaveId: string }
  >("opgave-options-opgave-page", undefined);

  const setLs = {
    setLsSortOrder,
    setLsBrugerId,
    setLsBrugerStatus,
    setLsKommuneNr,
    setLsOpgavePage,
  };
  const [options, setOptions] = useState<OpgaveListePageOptions>({
    id: "",
    page: 1,
    pageSize: process.env.REACT_APP_OPGAVE_PAGE_SIZE
      ? parseInt(process.env.REACT_APP_OPGAVE_PAGE_SIZE)
      : 50,
    sortOrder: lsSortOrder,
    brugerId: lsBrugerId && authState.bruger ? authState.bruger.id : undefined,
    brugerStatus: lsBrugerStatus,
    kommunenr:
      authState.bruger && authState.bruger.erSkatteforvaltningen
        ? lsKommuneNr
        : undefined,
  });

  const nextPage = () => {
    setOptions((prevOptions) => ({
      ...prevOptions,
      page: prevOptions.page + 1,
    }));
  };

  const prevPage = () => {
    setOptions((prevOptions) => ({
      ...prevOptions,
      page: Math.max(1, prevOptions.page - 1),
    }));
  };

  const { data: currentPage, loading } = useTaskQuery(id, authState, options);

  const { data: taskList, loading: allLoading } = useTaskListQuery(id, options);

  const statusCount: IStatusCount = {
    all: taskList?.antalOpgaver ?? 0,
    doing: taskList?.antalOpgaverIgang ?? 0,
    done: taskList?.antalOpgaverAfsluttede ?? 0,
    unsolvable: taskList?.antalOpgaverUloeselige ?? 0,
    open: taskList?.antalOpgaverAabne ?? 0,
  };

  const total = (() => {
    switch (options.brugerStatus) {
      case undefined:
        return statusCount.all;
      case "1":
        return statusCount.doing;
      case "2":
        return statusCount.done;
      case "5":
        return statusCount.unsolvable;
      case "0":
        return statusCount.open;
      default:
        return 0;
    }
  })();

  useEffect(() => {
    if (
      options.id &&
      !(
        options.id === lsOpgavePage?.opgaveId &&
        options.page === lsOpgavePage?.page
      )
    ) {
      if (options.id) {
        setLsOpgavePage({
          page: options.page,
          opgaveId: options.id,
        });
      } else {
        setLsOpgavePage(undefined);
      }
    }
  }, [lsOpgavePage, setLsOpgavePage, options.page, options.id]);

  useEffect(() => {
    // Gem ændringer i sortorder, og filtre i localStorage,
    // så de kan bibeholdes på tvers af pageviews
    // e.g. gå ind på ejendom, og tryk "back" for at
    // komme tilbage til fejllisten, se issue #304
    setLsSortOrder(
      options.sortOrder ? parseInt(options.sortOrder) : OpgaveSortOrder.bfeAsc
    );
    setLsBrugerStatus(options.brugerStatus);
    setLsBrugerId(options.brugerId ? true : false);
    setLsKommuneNr(options.kommunenr);
  }, [
    setLsSortOrder,
    setLsBrugerStatus,
    setLsBrugerId,
    setLsKommuneNr,
    options.sortOrder,
    options.brugerId,
    options.brugerStatus,
    options.kommunenr,
  ]);

  if (taskList && (!options || options.id !== taskList.id)) {
    if (options.brugerStatus) {
      const currentBrugerStatuser = options.brugerStatus.split(",");
      const legalBrugerStatuser = [
        "0", // 0 allways allowed, but visesUI false for some reason.
        ...taskList.brugerstatus
          .filter((s) => s.visesUi)
          .map((s) => s.status.toString()),
      ];
      const newBrugerStatuser = currentBrugerStatuser
        .filter((s) => legalBrugerStatuser.indexOf(s) > -1)
        .join(",");

      setOptions({ ...options, brugerStatus: newBrugerStatuser });
    }
    const page =
      lsOpgavePage && lsOpgavePage.opgaveId === taskList.id
        ? lsOpgavePage.page
        : 1;

    setOptions({
      ...options,
      id: taskList.id,
      page,
    });
  }

  return (
    <TaskQueryContext.Provider
      value={{
        loading: loading || allLoading,
        options,
        statusCount: statusCount,
        currentPage: currentPage,
        taskList,
        total,
        setOptions,
        setLs,
        nextPage,
        prevPage,
      }}
    >
      {children}
    </TaskQueryContext.Provider>
  );
};

export const useTaskQuery = (
  id: string,
  authState: AuthState,
  options: OpgaveListePageOptions
) => {
  const [data, setData] = useState<Opgave[] | undefined>(undefined);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchData = async () => {
      if (authState.state === "IDLE" && id) {
        const result = await opgaveApi.getFromListe(id, authState, options);
        setData(result.data);
        setLoading(false);
      }
    };
    fetchData();
  }, [id, authState, options]);
  useRealTimeOpgaver(data, (opgaver) => setData(opgaver));
  return { data, loading };
};

export const useTaskListQuery = (
  id: string,
  options?: OpgaveListePageOptions
) => {
  const [data, setData] = useState<OpgaveListe | null>(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchData = async () => {
      const result = await opgavelisteApi.getListeFromId(id, options);
      setData(result);
      setLoading(false);
    };
    fetchData();
  }, [id, options]);
  return { data, loading };
};

export const TaskQueryContext = createContext<ITaskQueryContext>({
  loading: true,
  options: {
    page: 1,
    pageSize: 50,
  },
  total: 0,
  currentPage: undefined,
  taskList: null,
  statusCount: {
    all: 0,
    doing: 0,
    done: 0,
    unsolvable: 0,
    open: 0,
  },
  setLs: {
    setLsBrugerId: () => {},
    setLsBrugerStatus: () => {},
    setLsKommuneNr: () => {},
    setLsSortOrder: () => {},
    setLsOpgavePage: () => {},
  },
  setOptions: () => {},
  nextPage: () => {},
  prevPage: () => {},
});

export default TaskQueryProvider;
