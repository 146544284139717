import React, { useContext } from "react";
import { Redirect, useParams } from "react-router-dom";
import { motion } from "framer-motion";
import MainContainer from "components/common/layout/MainContainer";
import ListHeader from "./MainContent/ListHeader";
import Sublist from "./Sublist";
import { SearchResultContext } from "context/SearchResultProvider";
import TaskQueryProvider, { useTaskListQuery } from "context/TaskQueryProvider";
import Breadcrumbs from "./Breadcrumbs";

type RouteParams = { id: string };

export default function FejllisteID() {
  const { id } = useParams<RouteParams>();
  const { redirect } = useContext(SearchResultContext);
  const { data, loading } = useTaskListQuery(id);

  const renderLoading = () => {
    return Array.from(new Array(10)).map((o, i) => {
      return (
        <motion.li key={i} className={`${""}`}>
          <div className={""}>
            <div style={{ width: 100 + "%", height: 80 }} className={""}></div>
          </div>
        </motion.li>
      );
    });
  };

  if (loading) renderLoading();
  if (!data) return null;
  return (
    <TaskQueryProvider id={id}>
      <MainContainer>
        <Breadcrumbs
          currentList={{ navn: data.navn, id: id }}
          authState={undefined}
        />
        {redirect.state === true && <Redirect push to={redirect.path} />}
        {/* {result && (
          <>
            <p>Intet BFE-nummer fundet ved søgning.</p>
          </>
        )} */}
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          id="opgaveliste-main-content"
        >
          {data && <ListHeader opgaveliste={data} />}
          <motion.ul
            className="list-group w-100"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
          >
            {data && <Sublist opgaveliste={data} />}
            {/* {opgaverState?.state === "IDLE" &&
            renderOpgaver(
              opgaverState,
              opgaveliste,
              history,
              options ? options.sortOrder : 0
            )} */}
            {/* 
            {opgaverState.state === "IDLE" && opgaveliste && (
              <div className={styles.pagingbottom}>
                {options && (
                  <NextPreviousPage
                    pageOptions={options}
                    setPageOptions={setOptions}
                    totalCount={opgaverState.totaltAntal}
                  />
                )}
              </div>
            )}
          {opgaverState.state === "LOADING" && renderLoading()}
          {opgaverState.state === "ERROR" && renderError()} */}
            {/* {opgaverState.state === "LOADING" && renderLoading()} */}
          </motion.ul>
        </motion.div>
      </MainContainer>
    </TaskQueryProvider>
  );
}
